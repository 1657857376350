import { ManageAccountsOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme, Stack, Button } from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";

function ProfileUserWidget({ user }) {
  console.log("individual User Object", user);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const medium = palette.neutral.medium;

  const getDisplayName = () => {
    if (user.firstName == null && user.lastName == null) {
      return user.username;
    }
    return `${user.firstName} ${user.lastName}`;
  };

  return (
    <WidgetWrapper>
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        onClick={() => navigate(`/profile/${user.username}`)}
      >
        <FlexBetween gap="1rem">
          <UserImage image={user?.image} size="55px" />
          <Box>
            <Typography
              variant="h4"
              color={palette.primary.main}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              {getDisplayName()}
            </Typography>
          </Box>
        </FlexBetween>
        <ManageAccountsOutlined />
      </FlexBetween>
      <Box>
        <Typography>{user?.bio}</Typography>
        <br />
        <Stack direction={"row"} gap={"20px"}>
          <>
            <Typography color={medium}>
              {user?.followers?.length} followers
            </Typography>
            <Typography color={medium}>
              {user?.following?.length} following
            </Typography>
          </>
        </Stack>
      </Box>
    </WidgetWrapper>
  );
}

export default ProfileUserWidget;
