import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const users = useSelector((state) => state.auth.users)

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseURL}/notifications`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Something went wrong. Please try again later.");
        }

        const data = await response.json();
        console.log("notification list", data);

        setNotifications(data);
      } catch (err) {
        setError(
          err.message || "Something went wrong. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [baseURL, token]);

  const handleRedirect = (notification) => {
    if (notification.type === 'new_group') {
      // Navigate to the group chat page using typeId or other identifier
      navigate(`/chat/group`);
    } else if (notification.type === 'follow') {
      // Find the user by userId in the Redux store
      const user = users.find(u => u.id === notification.typeId);
      if (user) {
        // Navigate to the user profile using the username
        navigate(`/profile/${user.username}`);
      } else {
        console.error('User not found in the store');
      }
    } else if (notification.type === 'new_post' || notification.type === 'new_comment' || notification.type === 'new_post' || notification.type === 'post_like' ) {
      // Navigate to the specific post
      navigate(`/post/${notification.typeId}`);

    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" minHeight="100vh">
        <WidgetWrapper width="100%" maxWidth="600px" m="1rem">
          <Box>
            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && notifications.length === 0 && (
              <Typography>No notifications available</Typography>
            )}
            {!loading && !error && notifications.length > 0 && (
              <Box>
                {notifications.map((notification) => (
                  <Box
                    key={notification.id}
                    mb="1rem"
                    onClick={() =>
                      handleRedirect(notification)
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="h6" color={palette.primary.dark}>
                      {notification.type}
                    </Typography>
                    <Typography color={palette.neutral.dark}>
                      {notification.message}
                    </Typography>
                    <Typography color={palette.neutral.main} variant="body2">
                      {new Date(notification.createdAt).toLocaleString()}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </WidgetWrapper>
      </Box>
    </div>
  );
};

export default Notifications;
