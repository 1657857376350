import { DeleteOutlined, ImageOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  Modal,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import UserImage from "components/UserImage";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useSelector } from "react-redux";

const MyPostWidget = () => {
  const [isImage, setIsImage] = useState(false);
  const [post, setPost] = useState("");
  const [imageData, setImageData] = useState([]); // Store an array of image objects
  const [uploading, setUploading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [imageUploadError, setImageUploadError] = useState("");
  const [postError, setPostError] = useState("");
  const [showPostModal, setShowPostModal] = useState(false);
  const { palette } = useTheme();
  const token = useSelector((state) => state.auth.token);
  const image = useSelector((state) => state.auth.user.image);
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleImageUpload = async (file) => {
    setUploading(true);
    setImageUploadError("");
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${baseURL}/upload/multiple`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        if (data && data.length > 0) {
          setImageData((prevData) => [...prevData, data[0]]);
          console.log("image upload data", data);
        }
      } else {
        setImageUploadError("Something went wrong, try again.");
      }
    } catch (error) {
      setImageUploadError(error.message);
    } finally {
      setUploading(false);
    }
  };

  const handlePost = async () => {
    setPosting(true);
    setPostError("");

    try {
      const response = await fetch(`${baseURL}/posts`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: post || "", // Set title to empty string if post is falsy
          content: JSON.stringify(imageData), // Send array of image objects or empty array
        }),
      });

      if (response.ok) {
        setImageData([]); // Reset the image data after successful post
        setPost("");
        setShowPostModal(true); // Show modal when post is successfully created
      } else {
        setPostError(response.message || "Something went wrong, try again.");
      }
    } catch (error) {
      setPostError(error.message);
    } finally {
      setPosting(false);
    }
  };

  const closeModal = () => {
    setShowPostModal(false);
  };

  const isButtonDisabled = !post.trim() && imageData.length === 0;

  return (
    <WidgetWrapper mb="2rem">
      <FlexBetween gap="1.5rem">
        <UserImage image={image} />
        <Box
          sx={{
            width: "100%",
            backgroundColor: palette.neutral.light,
            borderRadius: "2rem",
            padding: "1rem 2rem",
          }}
        >
          <InputBase
            placeholder="What's on your mind..."
            onChange={(e) => setPost(e.target.value)}
            value={post}
            fullWidth
            multiline
            minRows={3}
            sx={{
              width: "100%",
              border: "none",
              background: "transparent",
              resize: "none",
              outline: "none",
              boxShadow: "none",
              fontFamily: "inherit",
              fontSize: "inherit",
            }}
          />
        </Box>
      </FlexBetween>

      {isImage && (
        <Box
          border={`1px solid ${medium}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Box
            border={`2px dashed ${palette.primary.main}`}
            p="1rem"
            width="100%"
            sx={{ "&:hover": { cursor: "pointer" } }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              handleImageUpload(file);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              style={{ display: "none" }}
              id="image-input"
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) =>
                  handleImageUpload(file)
                );
              }}
            />

            <label htmlFor="image-input" style={{ cursor: "pointer" }}>
              {!uploading && imageData.length === 0 && (
                <p>Drag & drop an image here, or click to select one</p>
              )}
            </label>

            {uploading && <CircularProgress />}
            {imageData.length > 0 && (
              <Box>
                {imageData.map((img, index) => (
                  <FlexBetween key={index}>
                    <Typography>{img.url.split("/").pop()}</Typography>
                    <IconButton
                      onClick={() =>
                        setImageData((prevData) =>
                          prevData.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </FlexBetween>
                ))}
              </Box>
            )}
          </Box>
          {imageUploadError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {imageUploadError}
            </Alert>
          )}
        </Box>
      )}

      <Divider sx={{ margin: "1.25rem 0" }} />

      <FlexBetween>
        <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
          <ImageOutlined sx={{ color: mediumMain }} />
          <Typography
            color={mediumMain}
            sx={{ "&:hover": { cursor: "pointer", color: medium } }}
          >
            Image
          </Typography>
        </FlexBetween>

        <Button
          disabled={posting || isButtonDisabled} // Disable if posting is in progress or both post and imageData are empty
          onClick={handlePost}
          sx={{
            color: palette.background.alt,
            backgroundColor: palette.primary.main,
            borderRadius: "3rem",
            "&:hover": {
              backgroundColor: palette.primary.dark, // Change this to your desired hover color
            },
            "&.Mui-disabled": {
              backgroundColor: palette.grey[500], // Color for disabled state
              color: palette.grey[700], // Text color for disabled state
            },
          }}
        >
          {posting ? <CircularProgress size={24} /> : "POST"}
        </Button>
      </FlexBetween>

      {postError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {postError}
        </Alert>
      )}

      <Modal
        open={showPostModal}
        onClose={closeModal}
        aria-labelledby="post-created-modal"
        aria-describedby="post-created-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="post-created-modal" variant="h6" component="h2">
            Post Created Successfully!
          </Typography>
          <Button onClick={closeModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </WidgetWrapper>
  );
};

export default MyPostWidget;
