import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Chats from "./Chats";
import { useTheme } from "@mui/material/styles";
import Conversation from "scenes/Chat/components/conversation";
import { useSelector } from "react-redux";
import Contact from "scenes/Chat/components/Contact";
import SharedMessages from "scenes/Chat/components/SharedMessages";
import NoChat from "scenes/Chat/assets/Illustration/NoChat";
import { Link } from "react-router-dom";

const GeneralApp = () => {
  const theme = useTheme();
  const {open,type,roomId,chatOpen} = useSelector((store) => store.sidebar.personal)
  
  return (
    <Stack direction={"row"} sx={{ width: "100%" }}>
      {/* Chats */}
      <Chats />
      <Box
        sx={{
          height: "100%",
          width: open ? "calc(100vw - 740px)" : "calc(100vw - 420px)",
          backgroundColor: "#F0F4FA",
        }}
      >
        {/* Conversation */}  
        {roomId !== null && chatOpen === "individual" 
        ? <Conversation /> :
        <Stack
              spacing={2}
              sx={{ height: "100%", width: "100%" }}
              alignItems="center"
              justifyContent={"center"}
            >
              <NoChat />
              <Typography variant="subtitle2">
                Select a conversation or start a{" "}
                <Link
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                  }}
                  to="/"
                >
                  new one
                </Link>
              </Typography>
            </Stack>
        }
        
      </Box>
     {/* Contact */}
     
     {open &&
        (() => {
          switch (type) {
            case "CONTACT":
              return <Contact chatType="personal" />;
            case "SHARED":
              return <SharedMessages chatType="personal" />;
            default:
              return <Contact chatType="personal"/>;
          }
        })()}

    </Stack>
  );
};

export default GeneralApp;