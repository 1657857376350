import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import SideBar from './layouts/dashboard/Sidenav';
import 'react-toastify/dist/ReactToastify.css'; // Import default styles
import ThemeProvider from './theme';

function Chat() {

  return (
    <ThemeProvider>
      <Stack direction="row" sx={{ height: "calc(100vh - 80px)", width: "100%" }}>
        <SideBar />
        <Outlet />
      </Stack>
    </ThemeProvider>
  );
}

export default Chat;
