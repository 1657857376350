import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const ImageModal = ({ open, handleClose, imageUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: 2,
          padding: 2,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            color: 'white', // White icon color for better visibility
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker on hover
            },
          }}
        >
          <Close />
        </IconButton>
        <Box
          component="img"
          src={imageUrl}
          alt="Zoomed"
          sx={{
            maxWidth: '100%',
            maxHeight: '80vh',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
