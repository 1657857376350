// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
}
.react-tel-input .form-control{
 height: 100% !important;
 width: 100% !important;
}

.react-tel-input{
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;EAIE,YAAY;EACZ,WAAW;EACX,gCAAgC;AAClC;AACA;CACC,uBAAuB;CACvB,sBAAsB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap\");\n\nhtml,\nbody,\n#root,\n.app {\n  height: 100%;\n  width: 100%;\n  font-family: \"Rubik\", sans-serif;\n}\n.react-tel-input .form-control{\n height: 100% !important;\n width: 100% !important;\n}\n\n.react-tel-input{\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
