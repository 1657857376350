import { Box, Button, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ProfileFriendListWidget = ({ user }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [showFollowers, setShowFollowers] = useState(true); // State to toggle between followers and followings
  const { followers, following } = user;

  const navigateToProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  return (
    <WidgetWrapper>
      <Box display="flex" justifyContent="space-between" mb="1rem">
        <Button
          variant={showFollowers ? "contained" : "outlined"}
          onClick={() => setShowFollowers(true)}
        >
          Followers
        </Button>
        <Button
          variant={!showFollowers ? "contained" : "outlined"}
          onClick={() => setShowFollowers(false)}
        >
          Followings
        </Button>
      </Box>

      
      <Box display="flex" flexDirection="column" gap="1rem">
        {showFollowers && !followers?.length && (
          <Typography>No followers found.</Typography>
        )}
        {!showFollowers && !following?.length && (
          <Typography>No followings found.</Typography>
        )}
        {(showFollowers ? followers : following)?.map((user) => (
          <Friend
            key={user.id}
            username={user.username}
            userPicturePath={user.image}
            userId={user.id}
            onClick={() => navigateToProfile(user.username)}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default ProfileFriendListWidget;
