import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can log the error to an error reporting service
      console.error("Caught an error:", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Render fallback UI
        return (
          <div className="error-boundary">
            <h1>Something went wrong.</h1>
            <p>Please try refreshing the page or contact support if the problem persists.</p>
          </div>
        );
      }
  
      // If there's no error, render the children components
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;