import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home"); 
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ea1717", 
        color: "#ffffff", 
        textAlign: "center",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "6rem", fontWeight: "bold" }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ffffff",
          color: "#ff1744",
          "&:hover": {
            backgroundColor: "#ff5f5f",
            color: "#ffffff",
          },
        }}
        onClick={handleGoHome}
      >
        Go Home
      </Button>
    </Box>
  );
};

export default Page404;
