import { client, xml } from "@xmpp/client";
import debug from "@xmpp/debug";
import { store } from "../index";
import { setXmppConnected } from "state/chat";

let xmpp;
const ofdomain = process.env.REACT_APP_OF_DOMAIN;

const setupXmppClient = (username, password) => {
  xmpp = client({
    service: `wss://${ofdomain}:7443/ws/`,
    domain: ofdomain,
    resource: `example-${Date.now()}`,
    username: username,
    password: password,
  });

  debug(xmpp, true);

  xmpp.on("error", (err) => {
    console.error("XMPP Error:", err);
    store.dispatch(setXmppConnected(false));
  });

  xmpp.on("status", (status) => {
    console.log("XMPP Status:", status);
    if (status === "online") {
      store.dispatch(setXmppConnected(true));
    } else if (status === "offline") {
      store.dispatch(setXmppConnected(false));
      xmpp.stop().catch(console.error);
    }
  });

  xmpp.on("online", (address) => {
    console.log("Online as:", address.toString());
    store.dispatch(setXmppConnected(true));
    if (xmpp.status === "online") {
      const presence = xml("presence");
      xmpp
        .send(presence)
        .then(() => {
          console.log("Presence sent:", presence.toString());
        })
        .catch((error) => {
          console.error("Error sending presence:", error);
        });
    } else {
      console.error("WebSocket is not ready to send presence.");
    }
  });

  xmpp.on("offline", () => {
    console.log("XMPP offline");
    store.dispatch(setXmppConnected(false));
  });

  xmpp.on("stanza", (stanza) => {
    console.log("Incoming stanza:", stanza.toString());
  });

  xmpp.start().catch((error) => {
    console.error("XMPP client start error:", error);
    store.dispatch(setXmppConnected(false));
  });

  return xmpp;
};

const joinGroupChat = (roomJid, nickname) => {
  if (!xmpp) {
    console.error("XMPP client is not initialized.");
    return;
  }

  if (!roomJid || !nickname) {
    console.log("Room JID and nickname must not be null or undefined.");
    return;
  }

  const domain = `conference.${ofdomain}`;
  console.log("Joining room:", roomJid, "with nickname:", nickname);

  const joinRoom = xml(
    "presence",
    { to: `${roomJid}@${domain}/${nickname}` },
    xml(
      "x",
      { xmlns: "http://jabber.org/protocol/muc" },
      xml("history", { maxstanzas: "0" }) // Request no history
    )
  );

  if (xmpp.status === "online") {
    xmpp
      .send(joinRoom)
      .then(() => {
        console.log(
          "Join room presence sent with no history:",
          joinRoom.toString()
        );
      })
      .catch((error) => {
        console.error("Error sending join room presence:", error);
      });
  } else {
    console.error("Cannot join room because XMPP connection is not online.");
  }
};

export { setupXmppClient, xmpp, xml, joinGroupChat };
