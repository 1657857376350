class GroupPeerService {
  constructor() {
    this.peerConnections = new Map(); // Store peers for each user
    this.pendingIceCandidates = new Map();
    this.localStream = null;
  }

  // Initialize a new peer connection for a user
  createPeerConnection(userId) {
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          urls: [
            "stun:stun.l.google.com:19302",
          ],
        },
      ],
    });

    // Handle ontrack event to add remote stream for each peer
    peer.ontrack = (event) => {
      const remoteStream = event.streams[0];
      console.log("Received remote track from user:", userId);
      if (this.onRemoteStream) {
          this.onRemoteStream(userId, remoteStream); // Trigger callback when remote track is received
        }
    };

    peer.onicecandidate = (event) => {
      if (event.candidate) {
        this.onIceCandidate?.(userId, event.candidate); // Call the callback for ICE candidate
      console.log("onicecandidate called");
      }
    };

    // Store the peer connection for this user
    this.peerConnections.set(userId, peer);

    console.log("Updated peerConnections map:", Array.from(this.peerConnections.entries()));
    return peer;
  }

  getPeerConnections() {
    return Array.from(this.peerConnections.values());
  }

  addLocalStream(userId, stream) {
      const peer = this.peerConnections.get(userId);
      if (!peer) {
        console.error(`Peer connection for user ${userId} not found.`);
        return;
      }
    
      if (!stream) {
        console.error('Local stream is not available');
        return;
      }
    
      try {
        stream.getTracks().forEach((track) => {
          peer.addTrack(track, stream);
          console.log(`Track added to peer connection for user: ${userId}`, track);
        });
      } catch (error) {
        console.error("Error adding local track", error);
      }
    }

  // Create an offer for a specific user
  async createOffer(userId) {
    const peer = this.peerConnections.get(userId);
    if (peer) {
      try{
          const offer = await peer.createOffer();
          await peer.setLocalDescription(offer);
          return offer;
      }catch(error){
          console.error("error creating offer",error);
      }
    }
    return null;
  }

  // Set remote description and create an answer for a specific user
  async createAnswer(userId, offer) {
    const peer = this.peerConnections.get(userId);
    if (peer) {
      try {
        await peer.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peer.createAnswer();
        await peer.setLocalDescription(answer);

        // Add any pending ICE candidates after remote description is set
        const pendingCandidates = this.pendingIceCandidates.get(userId);
        if (pendingCandidates && pendingCandidates.length > 0) {
          console.log(`Adding ${pendingCandidates.length} pending ICE candidates for user: ${userId}`);
          for (const candidate of pendingCandidates) {
            await peer.addIceCandidate(new RTCIceCandidate(candidate));
          }
          this.pendingIceCandidates.delete(userId);
        }

        return answer;
      } catch (error) {
        console.error("error creating answer : ", error);
      }
    }
    return null;
  }


  async setRemoteDescription(userId, answer) {
    const peer = this.peerConnections.get(userId);
    if (peer) {
      try {
        await peer.setRemoteDescription(new RTCSessionDescription(answer));
        console.log("remote description set for", userId);
        
        // Add any pending ICE candidates after remote description is set
        const pendingCandidates = this.pendingIceCandidates.get(userId);
        if (pendingCandidates && pendingCandidates.length > 0) {
          console.log(`Adding ${pendingCandidates.length} pending ICE candidates for user: ${userId}`);
          for (const candidate of pendingCandidates) {
            await peer.addIceCandidate(new RTCIceCandidate(candidate));
          }
          this.pendingIceCandidates.delete(userId);
        }
      } catch (error) {
        console.error("error setting remote description : ", error);
      }
    }
  }

  setRemoteStreamCallback(callback) {
      this.onRemoteStream = callback;
    }

  onIceCandidate(callback) {
      this.onIceCandidate = callback; // Set the callback to handle ICE candidates
  }

  async addIceCandidate(userId, candidate) {
    const peer = this.peerConnections.get(userId);
    if (peer) {
      try {
        // Check if remote description is set
        if (peer.remoteDescription && peer.remoteDescription.type) {
          await peer.addIceCandidate(new RTCIceCandidate(candidate));
          console.log(`Added ICE candidate for user: ${userId}`);
        } else {
          // Store the candidate if remote description isn't set yet
          if (!this.pendingIceCandidates.has(userId)) {
            this.pendingIceCandidates.set(userId, []);
          }
          this.pendingIceCandidates.get(userId).push(candidate);
          console.log(`Stored pending ICE candidate for user: ${userId}`);
        }
      } catch (error) {
        console.error(`Error adding ICE candidate for user: ${userId}`, error);
      }
    }
  }

  closeConnection(userId) {
      const peer = this.peerConnections.get(userId);
      if (peer) {
        peer.close();
        this.peerConnections.delete(userId);
        this.pendingIceCandidates.delete(userId);
        console.log(`Peer connection closed for user: ${userId}`);
      }
    }

  closeAllConnections() {
    this.peerConnections.forEach((peer, userId) => {
      peer.close();
      console.log(`Peer connection closed for user: ${userId}`);
    });
    this.peerConnections.clear();
    this.pendingIceCandidates.clear();
  }
}

export default new GroupPeerService();
