import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGroupCalling: false,
  isReceivingGroupCall: false,
  groupCallInProgress: false,
  groupCallModalOpen: false, // Controls modal visibility for group calls
  groupCaller: null,
  groupCallee: null,
  groupParticipants: [], // Array to store group call participants
  groupCalleeInfo: null,
  groupInfo: null, // Info about the group in case of a group call
  groupCallError: null,
  groupOffers:[],
  remoteStreams: {},
  groupCallType: null,
};

const groupCallSlice = createSlice({
  name: 'groupCall',
  initialState,
  reducers: {
    setGroupCaller(state, action) {
      state.groupCaller = action.payload;
    },
    setGroupCallee(state, action) {
      state.groupCallee = action.payload;
    },
    setGroupCalleeInfo(state, action) {
      state.groupCalleeInfo = action.payload;
    },
    setGroupInfo(state, action) {
      state.groupInfo = action.payload;
    },
    addGroupParticipant(state, action) {
      state.groupParticipants.push(action.payload);
    },
    removeGroupParticipant(state, action) {
      state.groupParticipants = state.groupParticipants.filter(
        (participant) => participant.id !== action.payload.id
      );
    },
    setIsGroupCalling(state, action) {
      state.isGroupCalling = action.payload;
      state.groupCallModalOpen = action.payload || state.isReceivingGroupCall || state.groupCallInProgress;
    },
    setIsReceivingGroupCall(state, action) {
      state.isReceivingGroupCall = action.payload;
      state.groupCallModalOpen = action.payload || state.isGroupCalling || state.groupCallInProgress;
    },
    setGroupCallInProgress(state, action) {
      state.groupCallInProgress = action.payload;
      state.groupCallModalOpen = action.payload || state.isGroupCalling || state.isReceivingGroupCall;
    },
    setGroupCallType(state, action) {
      state.groupCallType = action.payload;
    },
    closeGroupCallModal(state) {
      state.groupCallModalOpen = false;
      state.isGroupCalling = false;
      state.isReceivingGroupCall = false;
      state.groupCallInProgress = false;
      state.groupCalleeInfo = null;
      state.groupCaller = null;
      state.groupParticipants = [];
      state.groupInfo = null;
      state.remoteStreams ={};
      state.groupCallType = null;
    },
    setGroupCallError(state, action) {
      state.groupCallError = action.payload;
    },
    addGroupOffer: (state, action) => {
      state.groupOffers.push(action.payload);
    },
    clearGroupOffers: (state) => {
      state.groupOffers = [];  
    },
    addRemoteStream: (state, action) => {
      const { userId, streamId } = action.payload;
      state.remoteStreams[userId] = { streamId };
    },
    removeRemoteStream: (state, action) => {
      const { userId } = action.payload;
      delete state.remoteStreams[userId];
    },
    clearAllStreams: (state) => {
      state.remoteStreams = {};
    }
  },
});

export const {
  setGroupCaller,
  setGroupCallee,
  setGroupCalleeInfo,
  setGroupInfo,
  addGroupParticipant,
  removeGroupParticipant,
  setIsGroupCalling,
  setIsReceivingGroupCall,
  setGroupCallInProgress,
  closeGroupCallModal,
  setGroupCallError,
  addGroupOffer,
  clearGroupOffers,
  addRemoteStream,
  removeRemoteStream,
  clearAllStreams,
  setGroupCallType,
} = groupCallSlice.actions;

export default groupCallSlice.reducer;
