import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton, Link } from "@mui/material";
import { MagnifyingGlass, Plus } from "phosphor-react";
import { useTheme } from "@mui/material/styles";
import ScrollContainer from "../../components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import ChatElement from "../../components/ChatElement";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search";
import CreateGroup from "scenes/Chat/sections/Dashboard/CreateGroup";
import GroupConversation from "scenes/Chat/components/groupconversation";
import SharedMessages from "scenes/Chat/components/SharedMessages";
import NoChat from "scenes/Chat/assets/Illustration/NoChat";
import { fetchGroups } from "state/groupSlice"; // Import the thunk
import Contact from "scenes/Chat/components/Contact";

const Group = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const { open, type, roomId, chatOpen } = useSelector((store) => store.sidebar.group);
  const { groups, status, error } = useSelector((store) => store.groups); // Access group state
  const userId = useSelector((state) => state.auth.user.id);
  const token = useSelector((store) => store.auth.token);

  // Handle dialog open/close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Fetch groups when component mounts
  useEffect(() => {
    dispatch(fetchGroups({ userId, token }));
  }, [dispatch]);

  // Callback to reload groups
  const reloadGroups = () => {
    dispatch(fetchGroups({ userId, token }));
  };

  // Filtered groups based on search term
  const filteredGroups = groups.filter((group) =>
    group.naturalName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Stack direction={"row"} sx={{ height: "calc(100vh - 80px)" }}>
        <Box
          sx={{
            position: "relative",
            height: "calc(100vh - 80px)",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F8FAFF"
                : theme.palette.background.default,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Stack p={3} spacing={2} sx={{ height: "100%", width: 320 }}>
            <Stack
              alignItems={"center"}
              justifyContent="space-between"
              direction="row"
            >
              <Typography variant="h5">Groups</Typography>
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <Search>
                <SearchIconWrapper>
                  <MagnifyingGlass color="#709CE6" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Search>
            </Stack>
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
              direction={"row"}
            >
              <Typography variant="subtitle2" sx={{}} component={Link}>
                Create New Group
              </Typography>
              <IconButton onClick={handleOpenDialog}>
                <Plus style={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>
            <Stack
              spacing={2}
              direction="column"
              sx={{ flexGrow: 1, overflow: "hidden", height: "100%" }}
            >
              <ScrollContainer>
                <Stack spacing={2.4}>
                  <Typography variant="subtitle2" sx={{ color: "#676767" }}>
                    All Chats
                  </Typography>
                  {status === 'loading' && <Typography>Loading...</Typography>}
                  {status === 'failed' && <Typography>Error: {error}</Typography>}
                  {status === "succeeded" && filteredGroups.length === 0 && (
                    <Typography>No chats available</Typography>
                  )}
                  {status === "succeeded" && filteredGroups.length > 0 && filteredGroups.map((group) => (
                    <ChatElement key={group.id} id={group.id} name={group.naturalName} chatType="group" />
                  ))}
                </Stack>
              </ScrollContainer>
            </Stack>
          </Stack>
          {openDialog && <CreateGroup open={openDialog} handleClose={handleCloseDialog} onGroupCreated={reloadGroups} />}
        </Box>
        <Box
          sx={{
            height: "100%",
            width: open ? "calc(100vw - 740px)" : "calc(100vw - 420px)",
            backgroundColor: "#F0F4FA",
          }}
        >
          {roomId !== null && chatOpen === "individual"
            ? <GroupConversation />
            : <Stack
                spacing={2}
                sx={{ height: "100%", width: "100%" }}
                alignItems="center"
                justifyContent={"center"}
              >
                <NoChat />
                <Typography variant="subtitle2">
                  Select a conversation or start a{" "}
                  <Link
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                    }}
                    to="/"
                  >
                    new one
                  </Link>
                </Typography>
              </Stack>
          }
        </Box>
        <Stack>
          {open &&
            (() => {
              switch (type) {
                case "CONTACT":
                  return <Contact chatType="group" />;
                case "SHARED":
                  return <SharedMessages chatType="group" />;
                default:
                  return <Contact chatType="group" />;
              }
            })()}
        </Stack>
      </Stack>
    </>
  );
};

export default Group;
