import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { setFcmToken } from "state";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

let app;
let messaging;

const initializeFirebase = async () => {
  try {
    app = initializeApp(firebaseConfig);

    // Check if the browser supports service workers
    if (!("serviceWorker" in navigator)) {
      console.log("This browser does not support service workers");
      return false;
    }

    // Register service worker
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js",
      {
        scope: "/",
      }
    );
    console.log("Service Worker registered successfully:", registration);

    messaging = getMessaging(app);
    return true;
  } catch (error) {
    console.error("Error initializing Firebase:", error);
    return false;
  }
};

let currentToken = null;
const baseUrl = process.env.REACT_APP_BASE_URL;
const vapid_key = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export const initializeFCM = async (token, dispatch, fcmToken) => {
  try {
    const isInitialized = await initializeFirebase();
    if (!isInitialized) {
      console.log("Firebase could not be initialized");
      return;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      await getAndSendToken(token, dispatch, fcmToken);
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error initializing FCM:", error);
  }
};

export const getAndSendToken = async (token, dispatch, storedFcmToken) => {
  try {
    const swRegistration = await navigator.serviceWorker.getRegistration();
    const newToken = await getToken(messaging, {
      vapidKey: vapid_key,
      serviceWorkerRegistration: swRegistration,
    });

    if (newToken) {
      console.log("FCM token:", newToken);

      if (newToken !== storedFcmToken) {
        console.log("New FCM token detected, updating...");
        dispatch(setFcmToken(newToken));
        await sendTokenToServer(token, newToken);
        currentToken = newToken;
      } else {
        console.log("FCM token unchanged, no update required.");
      }
    } else {
      console.log("No registration token available.");
    }
  } catch (error) {
    console.error("Error getting new token:", error);
  }
};

const sendTokenToServer = async (token, newToken) => {
  try {
    const response = await fetch(`${baseUrl}/users/fcm-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Assuming you store the auth token in localStorage
      },
      body: JSON.stringify({ token: newToken }),
    });

    console.log("response from backend when sending token", response);

    if (!response.ok) {
      throw new Error("Failed to send token to server");
    }

    console.warn("Token sent to server successfully");
  } catch (error) {
    console.error("Error sending token to server:", error);
  }
};

export const setupMessageListener = (token) => {
  if (token) {
    console.log("Message listener set for FCM");
    onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);

      // Extract notification details from the payload
      const { title, body } = payload.notification || {};

      // Display the toast notification with title and body
      if (title && body) {
        toast.info(
          <div>
            <strong>{title}</strong>
            <p>{body}</p>
          </div>
        );
      } else {
        console.warn("No title or body found in FCM payload.");
      }
    });
  }
};
