import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Box, Typography, Button, IconButton, Avatar } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import { useSocket } from "context/SocketProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  closeGroupCallModal,
  setGroupCallInProgress,
  setGroupCallType,
  setGroupInfo,
  setIsGroupCalling,
  setIsReceivingGroupCall,
} from "state/groupCall";
import GroupPeerService from "services/groupPeerService";
import { toast } from "react-toastify";
import groupPeerService from "services/groupPeerService";


const GroupCallModal = () => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const {
    groupCallModalOpen,
    isGroupCalling,
    isReceivingGroupCall,
    groupCallInProgress,
    groupCalleeInfo,
    groupCaller,
    groupParticipants,
    groupInfo,
    groupCallType,
  } = useSelector((state) => state.groupcall);
  const currentUser = useSelector((state) => state.auth.user);
  const { roomId, name } = useSelector((state) => state.sidebar.group);
  const [micOn, setMicOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [currentRemoteUser, setCurrentRemoteUser] = useState(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [participants, setParticipants] = useState(new Map());
  const [screenStream, setScreenStream] = useState(null);
  const [isCameraAvailable, setIsCameraAvailable] = useState(true);
  const fullScreenRef = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRefs = useRef({});
  const localAudioRef = useRef(null);
  const localStreamRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullScreenRef.current.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
    if (localAudioRef.current && localStream) {
      localAudioRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    remoteStreams.forEach(({ userId, remoteStream }) => {
      if (remoteVideoRefs.current[userId] && remoteStream) {
        remoteVideoRefs.current[userId].srcObject = remoteStream;
      }
    });
  }, [remoteStreams]);

  useEffect(() => {
    // Set callback to handle new remote streams (both audio and video)
    GroupPeerService.setRemoteStreamCallback((userId, remoteStream) => {
      setRemoteStreams((prevStreams) => {
        // Check if a stream for this user already exists
        const existingStreamIndex = prevStreams.findIndex(
          (stream) => stream.userId === userId
        );

        if (existingStreamIndex !== -1) {
          // If stream exists, update with the new track only if it's a different stream
          const existingStream = prevStreams[existingStreamIndex].remoteStream;
          if (existingStream !== remoteStream) {
            const updatedStreams = [...prevStreams];
            updatedStreams[existingStreamIndex] = { userId, remoteStream };
            return updatedStreams;
          }
          // If the stream is the same, return previous state
          return prevStreams;
        }

        // Add new user stream if it doesn't exist
        return [...prevStreams, { userId, remoteStream }];
      });
    });

    // Clean up callback on unmount
    return () => {
      GroupPeerService.setRemoteStreamCallback(null);
    };
  }, [socket]);

  useEffect(() => {
    if (groupCallModalOpen && !isReceivingGroupCall) {
      handleCallGroup();
      handleJoinRoom(roomId);
    }
    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [groupCallModalOpen]);

  useEffect(() => {
    if (!groupCallModalOpen) {
      // Clear participants Map when modal closes
      setParticipants(new Map());
    }
  }, [groupCallModalOpen]);

  const handleJoinRoom = async (roomId) => {
    try {
      const payload = {
        groupId: roomId,
        groupName: name,
        platform: "web",
        user: {
          id: currentUser?.id,
          name: currentUser?.username,
        },
      };
      socket.emit("group-join-room", payload);
      console.log("joined room");
    } catch (error) {
      console.error("error joining room");
    }
  };

  const startGroupWithCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: groupCallType === 'video'
      });
  
      console.log("local camera stream for group", stream);
      
      setLocalStream(stream);
      setIsCameraAvailable(true);
      
      // Emit group creation event
      const payload = {
        groupId: roomId,
        groupName: name,
        user: {
          id: currentUser?.id,
          name: currentUser?.username,
        },
        groupCallType: groupCallType,
      };
      socket.emit("group-create-room", payload);
      dispatch(setGroupInfo(payload));
      
      return true;
    } catch (error) {
      console.error("Failed to access camera:", error);
      setIsCameraAvailable(false);
      return false;
    }
  };
  
  const startGroupWithScreenShare = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true
      });
  
      console.log("local screen share stream for group", stream);
      
      setLocalStream(stream);
      setIsScreenSharing(true);
      setScreenStream(stream);
      
      // Handle screen sharing stop
      stream.getVideoTracks()[0].onended = () => {
        handleEndCall();
      };
  
      // Emit group creation event
      const payload = {
        groupId: roomId,
        groupName: name,
        user: {
          id: currentUser?.id,
          name: currentUser?.username,
        },
        groupCallType: groupCallType,
      };
      socket.emit("group-create-room", payload);
      dispatch(setGroupInfo(payload));
      
      return true;
    } catch (error) {
      console.error("Failed to start screen sharing:", error);
      toast.error("Failed to start screen sharing");
      return false;
    }
  };
  
  const handleCallGroup = async () => {
    try {
      // First try to start with camera
      const cameraSuccess = await startGroupWithCamera();
      
      // If camera fails and it's a video call, automatically prompt for screen sharing
      if (!cameraSuccess && groupCallType === 'video') {
        const screenSuccess = await startGroupWithScreenShare();
        if (!screenSuccess) {
          throw new Error("Both camera and screen sharing failed");
        }
      } else if (!cameraSuccess && groupCallType === 'audio') {
        // For audio calls, try with audio only
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          setLocalStream(audioStream);
          
          const payload = {
            groupId: roomId,
            groupName: name,
            user: {
              id: currentUser?.id,
              name: currentUser?.username,
            },
            groupCallType: groupCallType,
          };
          socket.emit("group-create-room", payload);
          dispatch(setGroupInfo(payload));
        } catch (error) {
          console.error("Failed to get audio stream:", error);
          toast.error("Failed to access microphone");
          throw error;
        }
      }
    } catch (error) {
      console.error("Failed to start group call:", error);
      toast.error("Failed to start group call. Please check your device permissions.");
      handleEndCall();
    }
  };

  const handleCallReceived = useCallback(
    (payload) => {
      console.log("group call received",payload);
      const groupcalltype = payload.groupCallType;
      console.log("group-call type :",groupcalltype);
      
      dispatch(setGroupCallType(groupcalltype));
      dispatch(setGroupInfo(payload)); // Ensure correct group info is dispatched
      // Indicate the app is receiving a group call
      dispatch(setIsReceivingGroupCall(true));
    },
    [dispatch]
  );

  const handleGroupUserConnected = useCallback(
    async (payload) => {
      console.log(`${payload.userId} has joined the group call`);

      // Existing users (A and B) send offer only to the newly joined user (C)
      if (payload.userId !== currentUser?.id) {
        GroupPeerService.createPeerConnection(payload.userId);
        console.log("localstream :", localStream);

        if (localStream) {
          // Check if localStream is initialized
          GroupPeerService.addLocalStream(payload.userId, localStream);
        } else {
          console.error("Local stream is not initialized yet");
        }
        const offer = await GroupPeerService.createOffer(payload.userId);
        socket.emit("group-offer", {
          groupId: groupInfo?.groupId,
          offer,
          participantId: payload.userId, // Send only to the newly joined user
          groupName: groupInfo?.groupName,
          user: {
            id: currentUser?.id,
            name: currentUser?.username,
          },
        });
      }
    },
    [
      currentUser?.id,
      currentUser?.username,
      localStream,
      socket,
      groupInfo?.groupId,
      groupInfo?.groupName,
    ]
  );

  const handleGroupOffer = useCallback(
    async (payload) => {
      console.log("group-offer", payload);
  
      if (payload?.participantId !== currentUser?.id) {
        setParticipants(prevParticipants => {
          const newMap = new Map(prevParticipants);
          newMap.set(payload.user.id, payload.user.name);
          return newMap;
        });
  
        try {
          console.log("Processing individual offer for the current user.");
          const offer = payload?.offer;
          console.log("offer received from:", payload?.participantId);
  
          GroupPeerService.createPeerConnection(payload?.participantId);
  
          // Get media stream here when handling offer
          let stream = null;
          
          if (groupCallType === "video") {
            try {
              // First try to get camera stream
              stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true,
              });
              console.log("local camera stream when handling offer", stream);
              setIsCameraAvailable(true);
            } catch (error) {
              console.error("Camera access failed in group call, trying screen share:", error);
              setIsCameraAvailable(false);
  
              // Try screen sharing as fallback
              stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true,
              });
  
              console.log("local screen share stream when handling offer", stream);
              setIsScreenSharing(true);
              setScreenStream(stream);
  
              // Handle screen share stop
              stream.getVideoTracks()[0].onended = () => {
                handleEndCall();
              };
            }
          } else {
            // For audio-only group calls
            stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
              video: false,
            });
          }
  
          if (stream) {
            setLocalStream(stream);
            await GroupPeerService.addLocalStream(payload?.participantId, stream);
          } else {
            throw new Error("Failed to get media stream");
          }
  
          const answer = await GroupPeerService.createAnswer(
            payload?.participantId,
            offer
          );
          
          socket.emit("group-answer", {
            groupId: groupInfo?.groupId,
            answer,
            participantId: payload?.participantId,
            groupName: groupInfo?.groupName,
            user: {
              id: currentUser?.id,
              name: currentUser?.username,
            },
          });
          console.log("answer sent to:", payload?.participantId);
  
          setCurrentRemoteUser({
            id: payload?.user?.id,
            name: payload?.user?.name,
          });
        } catch (error) {
          console.error("error handling offer:", error);
          toast.error(
            "Failed to join group call. Please check your device permissions."
          );
          handleDeclineGroupCall();
        }
      } else {
        console.log(
          "Skipping offer as it was not for a group call or the target user."
        );
      }
    },
    [currentUser?.id, currentUser?.username, dispatch, socket, groupCallType]
  );

  useEffect(() => {
    console.log("remotestreams :", remoteStreams);
    return;
  }, [remoteStreams]);

  const handleGroupAnswer = useCallback(async ({ answer, participantId, user }) => {
    try {
      setParticipants(prevParticipants => {
        const newMap = new Map(prevParticipants);
        newMap.set(user.id, user.name);
        return newMap;
      });
      console.log("group call answer received from :", participantId);
      console.log("received answer :", answer);
      groupPeerService.setRemoteDescription(participantId, answer);

      setCurrentRemoteUser({
        id: participantId,
      });
    } catch (error) {
      console.error("error setting remote description", error);
    }
  }, []);

  const handleGroupCallDeclined = useCallback(
    async (payload) => {
      console.log("call declined", payload);
      if (payload?.targetUserId !== currentUser?.id) {
        return;
      }
      toast.info(`call was declined by ${payload.user.name}`);
    },
    [currentUser?.id]
  );

  const handleGroupIceCandidate = useCallback(({ targetId, candidate }) => {
    try {
      console.log(`Received ICE candidate from :${targetId}`, candidate);
      GroupPeerService.addIceCandidate(targetId, candidate);
    } catch (error) {
      console.error("error receiving ice candidate", error);
    }
  }, []);

  const handleUserLeftRoom = useCallback(async (payload) => {
    const { userId } = payload;

    console.log(`${userId} has left the group call`);
    // toast.info(`${userId} has left the group call`);

    setParticipants((prevParticipants) => {
      const newParticipants = new Map(prevParticipants);
      newParticipants.delete(userId);
      return newParticipants;
    });

    GroupPeerService.closeConnection(userId);

    // Remove the user's stream from the remote streams
    setRemoteStreams((prevStreams) => {
      return prevStreams.filter((stream) => stream.userId !== userId);
    });
  }, []);

  useEffect(() => {
    if (socket) {
      console.log("Socket and Peer connection are established.");

      GroupPeerService.onIceCandidate((userId, candidate) => {
        // console.log(`ice candidate sent to ${userId}`,candidate);

        socket.emit("group-ice-candidate", {
          targetId: userId,
          candidate,
          user: {
            id: currentUser?.id,
            name: currentUser?.username,
          },
          groupId: groupInfo?.groupId,
        });
      });

      socket.on("invite-group-user-connected", handleCallReceived);
      socket.on("group-participant-joined", handleGroupUserConnected);
      socket.on("group-offer", handleGroupOffer);
      socket.on("group-answer", handleGroupAnswer);
      socket.on("group-call-declined", handleGroupCallDeclined);
      socket.on("group-ice-candidate", handleGroupIceCandidate);
      socket.on("group-user-disconnected", handleUserLeftRoom);
      return () => {
        socket.off("invite-group-user-connected", handleCallReceived);
        socket.off("group-participant-joined", handleGroupUserConnected);
        socket.off("group-offer", handleGroupOffer);
        socket.off("group-answer", handleGroupAnswer);
        socket.off("group-call-declined", handleGroupCallDeclined);
        socket.off("group-ice-candidate", handleGroupIceCandidate);
        socket.off("group-user-disconnected", handleUserLeftRoom);
      };
    } else {
      if (!socket) {
        console.log("Socket is not initialized yet.");
      }
    }
  }, [
    socket,
    handleGroupUserConnected,
    handleGroupOffer,
    handleGroupAnswer,
    handleGroupCallDeclined,
    handleGroupIceCandidate,
    handleCallReceived,
    currentUser?.id,
    currentUser?.username,
    groupInfo?.groupId,
    handleUserLeftRoom,
  ]);

  const handleDeclineGroupCall = () => {
    const payload = {
      targetUserId: groupInfo?.user?.id,
      user: {
        id: currentUser?.id,
        name: currentUser?.username,
      },
    };

    socket.emit("group-decline-call", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    dispatch(closeGroupCallModal());
    setLocalStream(null);
    setRemoteStreams([]);
    setMicOn(true);
    setVideoOn(true);
    setParticipants(new Map());
    setScreenStream(null);
    setIsCameraAvailable(true);
    setIsScreenSharing(false);
  };

  const handleEndCall = () => {
    const payload = {
      user: {
        id: currentUser.id,
        name: currentUser.username,
      },
      groupId: groupInfo.groupId,
    };

    socket.emit("group-leave-room", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    screenStream?.getTracks().forEach(track => track.stop());
    setLocalStream(null);
    setRemoteStreams([]);
    setMicOn(true);
    setVideoOn(true);
    setIsScreenSharing(false);
    setParticipants(new Map());
    setScreenStream(null);
    setIsCameraAvailable(true);
    dispatch(closeGroupCallModal());
    groupPeerService.closeAllConnections();
  };

  const handleAcceptGroupCall = async () => {
    try {
      console.log("accepted group call:", groupInfo?.groupId);
  
      // Just join room and update call states
      handleJoinRoom(groupInfo?.groupId);
      dispatch(setIsReceivingGroupCall(false));
      dispatch(setGroupCallInProgress(true));
  
    } catch (error) {
      console.error("Failed to join group call:", error);
      toast.error(
        "Failed to join group call. Please check your device permissions."
      );
      handleDeclineGroupCall();
    }
  };

  const toggleScreenShare = async () => {
    if (isScreenSharing) {
      if(isCameraAvailable){
      // Stop screen sharing and switch back to webcam
      const videoTrack = localStream.getVideoTracks()[0];
      videoTrack.stop();
      const webcamStream = await navigator.mediaDevices.getUserMedia({ video: true });
      const webcamTrack = webcamStream.getVideoTracks()[0];
  
      // Replace the screen-sharing track with the webcam track
      const peerConnections = GroupPeerService.getPeerConnections(); // Fix: no destructuring here
      peerConnections.forEach((pc) => {
        const sender = pc.getSenders().find(sender => sender.track.kind === 'video');
        if (sender) {
          sender.replaceTrack(webcamTrack);
        }
      });
  
      // Update localStream
      localStream.removeTrack(videoTrack);
      localStream.addTrack(webcamTrack);
  
      setIsScreenSharing(false);
      }else{
        const userConfirmed = window.confirm(
          "Call will be ended if u stop the screen sharing. Do you want to proceed?"
        );

        if (userConfirmed) {
          // User confirmed, proceed to stop the stream
          handleEndCall();
        } else {
          // User canceled, do not stop screen sharing
          return;
        }
      }
    } else {
      try {
        // Get the screen stream
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        const screenTrack = screenStream.getVideoTracks()[0];
  
        // Replace the webcam track with the screen-sharing track
        const peerConnections = GroupPeerService.getPeerConnections(); // Fix: no destructuring here
        peerConnections.forEach((pc) => {
          const sender = pc.getSenders().find(sender => sender.track.kind === 'video');
          if (sender) {
            sender.replaceTrack(screenTrack);
          }
        });
  
        // Update localStream
        const videoTrack = localStream.getVideoTracks()[0];
        localStream.removeTrack(videoTrack);
        localStream.addTrack(screenTrack);
  
        // Stop screen sharing when the user stops sharing
        screenTrack.onended = () => {
          toggleScreenShare(); // Revert to webcam when screen sharing stops
        };
  
        setIsScreenSharing(true);
      } catch (error) {
        console.error("Error sharing the screen:", error);
      }
    }
  };
  

  const toggleMic = () => {
    setMicOn((prev) => !prev);
    localStream
      ?.getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const toggleVideo = () => {
    setVideoOn((prev) => !prev);
    localStream
      ?.getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const renderCallStateText = () => {
    if (isGroupCalling) {
      return `Calling group members in ${
        groupInfo?.groupName || groupCalleeInfo?.name
      }...`;
    } else if (isReceivingGroupCall) {
      return `Incoming ${
        groupInfo?.groupName || groupCalleeInfo?.name
      } group call`;
    } else if (groupCallInProgress) {
      return `You are in a ${
        groupInfo?.groupName || groupCalleeInfo?.name
      } group call.`;
    } else {
      return "";
    }
  };

  const handleMediaError = (error) => {
    if (error.name === "NotAllowedError") {
      alert("Permission to access media devices was denied.");
      toast.error("Permission to access media devices was denied.");
    } else if (error.name === "NotFoundError") {
      alert("No media devices found. Please check your camera and microphone.");
      toast.error("No media devices found.");
    } else {
      alert("An error occurred while accessing media devices.");
      toast.error("An error occurred while accessing media devices.");
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: isFullScreen ? 0 : 2,
    boxShadow: isFullScreen ? "none" : 24,
    p: isFullScreen ? 0 : 4,
    width: isFullScreen ? "100%" : "auto",
    height: isFullScreen ? "100%" : "auto",
    maxWidth: isFullScreen ? "none" : 1200,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const contentContainerStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  };

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 4,
    flex: 1,
    overflowY: "auto",
    padding: 4,
    alignItems: "start",
  };

  const videoContainerStyle = {
    position: "relative",
    borderRadius: "10px",
    // overflow: "hidden",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    aspectRatio: "16 / 9",
    backgroundColor: "#f0f0f0",
    width: "100%",
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  };

  const streamLabelStyle = {
    position: "absolute",
    bottom: "8px",
    left: "8px",
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2px 8px",
    borderRadius: "4px",
    fontSize: "0.9rem",
  };

  const controlsStyle = {
    display: "flex",
    justifyContent: "center",
    gap: 2,
    padding: "10px 0",
    backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.5)" : "transparent",
  };

  const iconButtonStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "50%",
    padding: "10px",
    color: "#555",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#000",
    },
  };

  const iconInnerStyle = {
    fontSize: "1.5rem",
  };

  const answerButtonStyle = {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  };

  const declineButtonStyle = {
    borderColor: "red",
    color: "red",
    "&:hover": {
      borderColor: "darkred",
      color: "darkred",
    },
  };

  const endCallButtonStyle = {
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "darkred",
    },
  };

  const audioCallStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
    margin: "20px 0",
  };

  return (
    <Modal open={groupCallModalOpen}>
      <Box sx={modalStyle} ref={fullScreenRef}>
        <Box sx={contentContainerStyle}>
          {!isFullScreen && (
            <Typography variant="h5" component="h2" sx={{ textAlign: "center", mb: 2 }}>
              Group {groupCallType === 'video' ? "Video" : "Audio"} Call
            </Typography>
          )}
          <Typography variant="body1" sx={{ textAlign: "center", mb: 3 }}>
            {renderCallStateText()}
          </Typography>
          {!isReceivingGroupCall && groupCallType === 'video' && (
            <Box sx={gridContainerStyle}>
              {localStream && (
                <Box sx={videoContainerStyle}>
                  <video
                    ref={localVideoRef}
                    autoPlay
                    playsInline
                    muted
                    style={videoStyle}
                  />
                  <Typography sx={streamLabelStyle}>You</Typography>
                </Box>
              )}
              {remoteStreams.map(({ userId, remoteStream }) => (
                <Box sx={videoContainerStyle} key={userId}>
                  <video
                    ref={(el) => {
                      if (el) {
                        el.srcObject = remoteStream;
                      }
                    }}
                    autoPlay
                    playsInline
                    style={videoStyle}
                  />
                  <Typography sx={streamLabelStyle}>{participants.get(userId) || userId}</Typography>
                </Box>
              ))}
            </Box>
          )}
          {!isReceivingGroupCall && groupCallType === 'audio' && (
            <Box sx={audioCallStyle}>
              <Typography variant="h6">
                {groupCallInProgress ? "Call in progress" : "Calling..."}
              </Typography>
              <Typography>Group Audio Call</Typography>
              <audio ref={localAudioRef} autoPlay muted />
              {remoteStreams.map(({ userId, remoteStream }) => (
                <audio
                  key={userId}
                  ref={(el) => {
                    if (el) {
                      el.srcObject = remoteStream;
                    }
                  }}
                  autoPlay
                />
              ))}
              <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', gap: 3, alignItems: 'center' }}>
      {/* Local user */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 80, height: 80, borderRadius: '10px', backgroundColor: '#f5f5f5', padding: 1 }}>
        <Avatar sx={{ bgcolor: '#3f51b5', width: 40, height: 40 }}>Y</Avatar>
        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 1 }}>You</Typography>
      </Box>

      {/* Remote users */}
      {remoteStreams.map(({ userId }) => (
        <Box key={userId} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 80, height: 80, borderRadius: '10px', backgroundColor: '#f5f5f5', padding: 1 }}>
          <Avatar sx={{ bgcolor: '#f50057', width: 40, height: 40 }}>
          {(participants.get(userId) || userId).charAt(0).toUpperCase()}
          </Avatar>
          <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 1 }}>{participants.get(userId) || userId}</Typography>
        </Box>
      ))}
    </Box>
              
            </Box>
          )}
          <Box sx={controlsStyle}>
            {isReceivingGroupCall ? (
              <>
                <Button variant="contained" sx={answerButtonStyle} onClick={handleAcceptGroupCall}>
                  Answer Call
                </Button>
                <Button variant="outlined" sx={declineButtonStyle} onClick={handleDeclineGroupCall}>
                  Decline Call
                </Button>
              </>
            ) : (
              (isGroupCalling || groupCallInProgress) && (
                <>
                  <IconButton onClick={toggleMic} sx={iconButtonStyle}>
                    {micOn ? <MicIcon sx={iconInnerStyle} /> : <MicOffIcon sx={iconInnerStyle} />}
                  </IconButton>
                  {groupCallType === 'video' && (
                    <IconButton onClick={toggleVideo} sx={iconButtonStyle}>
                      {videoOn ? <VideocamIcon sx={iconInnerStyle} /> : <VideocamOffIcon sx={iconInnerStyle} />}
                    </IconButton>
                  )}
                  {groupCallType === 'video' && (
                    <IconButton onClick={toggleScreenShare} sx={iconButtonStyle}>
                      {isScreenSharing ? <StopScreenShareIcon sx={iconInnerStyle} /> : <ScreenShareIcon sx={iconInnerStyle} />}
                    </IconButton>
                  )}
                  <IconButton onClick={handleEndCall} sx={endCallButtonStyle}>
                    <CallEndIcon sx={iconInnerStyle} />
                  </IconButton>
                  {groupCallType === 'video' && (
                    <IconButton onClick={toggleFullScreen} sx={iconButtonStyle}>
                      {isFullScreen ? <FullscreenExitIcon sx={iconInnerStyle} /> : <FullscreenIcon sx={iconInnerStyle} />}
                    </IconButton>
                  )}
                </>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};


export default GroupCallModal;
