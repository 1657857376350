import React, { useState } from "react";
import {
  DeleteOutlined,
  MoreVert,
  PersonAddOutlined,
  PersonRemoveOutlined,
  FlagOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { addFollowing, removeFollowing } from "../state/index";
import MessageModal from "scenes/widgets/MessageModel";
import ReportPostModal from "scenes/widgets/ReportPostModal";
import { fetchUserData } from "state/thunks";

const Friend = ({
  username,
  userPicturePath,
  subtitle,
  handleDeleteComment,
  commentId,
  handleDeletePost,
  handleMenuClick,
  handleMenuClose,
  menuAnchorEl,
  menuOpen,
  deletingPost,
  postId,
  userId,
  isPost, // New prop to determine if this is a post
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const main = palette.neutral.main;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  const followings = useSelector((state) => state.auth.followings);
  const currentUser = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);

  const isFollowing = followings.some(
    (following) => following.username === username
  );

  const isCurrentUser = username === currentUser.username;

  // Handle follow/unfollow action
  const handleFollowUnfollow = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/${username}/follow`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setIsError(false);
        setModalMessage(data.message);
        dispatch(fetchUserData());

        // if (!isFollowing) {
        //   console.log("user id: ", userId);

        //   await sendFollowNotification(userId);
        // }
        // if (isFollowing) {
        //   dispatch(removeFollowing({ username }));
        // } else {
        //   dispatch(addFollowing({ username, image: userPicturePath }));
        // }
      } else {
        setIsError(true);
        setModalMessage(data.message);
      }
    } catch (error) {
      setIsError(true);
      setModalMessage("Something went wrong. Please try again.");
    } finally {
      setModalOpen(true);
    }
  };

  // const sendFollowNotification = async (receiverUserId) => {
  //   try {
  //     console.log(`Sending notification to user ID: ${receiverUserId}`);
  //     const notificationData = {
  //       notification: {
  //         title: "New Follower",
  //         body: `${currentUser.username} started following you!`,
  //       },
  //       data: {
  //         type: "follow",
  //         typeId: currentUser.id,
  //         followerUsername: currentUser.username,
  //       },
  //     };
  //     console.log("Notification payload:", notificationData);

  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/users/${receiverUserId}/send-notification`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(notificationData),
  //       }
  //     );

  //     if (response.ok) {
  //       console.log("Successfully sent follow notification");
  //     } else {
  //       console.error("Failed to send notification:");
  //     }
  //   } catch (error) {
  //     console.error("Error sending follow notification:", error);
  //   }
  // };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleReportPostClick = () => {
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
  };

  return (
    <>
      <FlexBetween>
        <FlexBetween gap="1rem">
          <UserImage image={userPicturePath} size="50px" />
          <Box
            onClick={() => {
              navigate(`/profile/${username}`);
            }}
          >
            <Typography
              color={main}
              variant="h5"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              {username}
            </Typography>
            <Typography>{subtitle}</Typography>
          </Box>
        </FlexBetween>
        {isPost ? (
          <IconButton onClick={handleMenuClick}>
            <MoreVert />
          </IconButton>
        ) : (
          !isCurrentUser && (
            <IconButton onClick={handleFollowUnfollow}>
              {isFollowing ? <PersonRemoveOutlined /> : <PersonAddOutlined />}
            </IconButton>
          )
        )}
      </FlexBetween>

      {isPost && (
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {!isCurrentUser && (
            <MenuItem onClick={handleFollowUnfollow}>
              {isFollowing ? <PersonRemoveOutlined /> : <PersonAddOutlined />}
              <Typography sx={{ ml: 1 }}>
                {isFollowing ? "Unfollow" : "Follow"}
              </Typography>
            </MenuItem>
          )}
          {commentId && handleDeleteComment && (
            <MenuItem onClick={() => handleDeleteComment(commentId)}>
              <DeleteOutlined />
              <Typography sx={{ ml: 1 }}>Delete Comment</Typography>
            </MenuItem>
          )}
          {!isCurrentUser && (
            <MenuItem onClick={handleReportPostClick}>
              <FlagOutlined />
              <Typography sx={{ ml: 1 }}>Report</Typography>
            </MenuItem>
          )}
          {isCurrentUser && handleDeletePost && (
            <MenuItem onClick={handleDeletePost}>
              {deletingPost ? (
                <CircularProgress size={24} />
              ) : (
                <DeleteOutlined />
              )}
              <Typography sx={{ ml: 1 }}>Delete Post</Typography>
            </MenuItem>
          )}
        </Menu>
      )}

      <MessageModal
        open={modalOpen}
        handleClose={handleCloseModal}
        message={modalMessage}
        isError={isError}
      />

      <ReportPostModal
        open={reportModalOpen}
        handleClose={handleCloseReportModal}
        postId={postId}
      />
    </>
  );
};

export default Friend;
