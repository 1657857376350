import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { xmpp, xml, joinGroupChat } from '../services/xmppConnection';
import { toast } from 'react-toastify';

const initialState = {
  personal: {
    open: false,
    type: 'CONTACT',
    chatType: 'personal',
    roomId: null,
    chatOpen: null,
    messages: [],
    messageIds: [],
    name: null,
    hasMoreMessages: true,
    offset: 0,
    limit: 20,
    loading: false,
    chatId :null,
    
  },
  group: {
    open: false,
    type: 'CONTACT',
    chatType: 'group',
    roomId: null,
    chatOpen: null,
    messages: [],
    messageIds: [],
    name: null,
    hasMoreMessages: true,
    offset: 0,
    limit: 20,
    loading: false,
    // loadingMore: false,
    chatId :null,
  },
  xmppConnected: false,
};

const ofdomain = process.env.REACT_APP_OF_DOMAIN;

// Function to extract username from JID
const extractUsername = (jid) => {
  const [username] = jid.split('@');
  return username;
};

export const sendNotification = createAsyncThunk(
  'chat/sendNotification',
  async ({ receiverUserId, message, token, username, isGroup = false, groupId = null }, { rejectWithValue }) => {
    try {
      let endpoint;
      let body;

      if (isGroup) {
        endpoint = `${process.env.REACT_APP_BASE_URL}/groups/${groupId}/send-notification`;
        body = JSON.stringify({
          notification: {
            title: 'New Group Message',
            body: message
          },
          data: {
            userName: username,
            groupId: groupId
          }
        });
      } else {
        endpoint = `${process.env.REACT_APP_BASE_URL}/users/${receiverUserId}/send-notification`;
        body = JSON.stringify({
          notification: {
            title: 'Message',
            body: message
          },
          data: {
            userName: username,
          }
        });
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: body
      });

      if (!response.ok) {
        throw new Error('Failed to send notification');
      }

      if (response.ok) {
        console.log("Notification sent successfully");
        console.log("Response from backend", response);
      }

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchChatHistory = createAsyncThunk(
  'chat/fetchChatHistory',
  async ({ oppositeUserId, chatType, token, isLoadingMore = false }, { getState, rejectWithValue }) => {
    const { limit, offset } = getState().sidebar[chatType];

    let endpoint;
    if (chatType === 'personal') {
      endpoint = `${process.env.REACT_APP_BASE_URL}/users/${oppositeUserId}/chat-history`;
    } else if (chatType === 'group') {
      endpoint = `${process.env.REACT_APP_BASE_URL}/groups/${oppositeUserId}/chat-history`;
    }

    try {
      const response = await fetch(`${endpoint}?limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch chat history: ${errorText}`);
      }
      const data = await response.json();
      console.log('API called for chat history', data);

      const messages = chatType === 'personal'
      ? data.map(item => ({
          id: `${item.sentdate}_${item.fromjid}_${item.tojid}`, // Create a unique ID
          from: extractUsername(item.fromjid),
          to: extractUsername(item.tojid),
          message: item.body,
        }))
      : data.message.map(item => ({
          id: `${item.delay_stamp}_${item.from}_${item.to}`, // Create a unique ID
          from: item.from,
          to: item.to,
          message: item.body,
        }));

      return { chatType, messages, isLoadingMore };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      const { chatType } = action.payload;
      state[chatType].open = !state[chatType].open;
    },
    updateSidebarType: (state, action) => {
      const { chatType, type } = action.payload;
      state[chatType].type = type;
    },
    selectConversation: (state, action) => {
      const { chatType, roomId, name, chatOpen, nickname, chatId } = action.payload;
      state[chatType].roomId = roomId;
      state[chatType].name = name;
      state[chatType].chatOpen = chatOpen;
      state[chatType].messages = [];
      state[chatType].messageIds = []; 
      state[chatType].offset = 0;
      state[chatType].hasMoreMessages = true;
      state[chatType].limit = 20;
      state[chatType].loading = false;
      state[chatType].chatId = chatId;
      

      if (chatType === 'group' && xmpp) { 
          joinGroupChat(roomId, nickname);
      }
    },
    sendMessage: (state, action) => {
      const { chatType, name, username, message, groupDetails } = action.payload;

      if (!state.xmppConnected) {
        console.log('XMPP is not connected. Cannot send message.');
        toast.error("XMPP is not connected. Cannot send message.");
        return;
      }

      const domain = ofdomain;
      const domain2 = `conference.${ofdomain}`;
      const jid = chatType === 'group' ? `${groupDetails.roomJid}@${domain2}` : `${name.toLowerCase()}@${domain}`;

      const newMessage = {
        id: `${new Date().getTime()}_${username}_${name}`,
        from: username,
        to: name,
        message,
        senderFirstName: groupDetails.firstName,
        senderLastName: groupDetails.lastName,
        senderJID: jid,
        senderWalletAddress: groupDetails.walletAddress,
        roomJid: groupDetails.roomJid,
        isSystemMessage: "",
        tokenAmount: "",
        quickReplies: "",
        notDisplayedValue: groupDetails.notDisplayedValue,
      };

      console.log(newMessage);

      if (!state[chatType].messageIds.includes(newMessage.id)) {
        if (chatType === 'group') {
          state[chatType].messages.push(newMessage);
        } else {
          state[chatType].messages.unshift(newMessage);
        }
        state[chatType].messageIds.push(newMessage.id);
      }

      if (xmpp) {
        const msg = chatType === 'group' ? xml(
          'message',
          {
            to: jid,
            type: "groupchat",
            id: "sendMessage",
          },
          xml("data", {
            senderFirstName: groupDetails.firstName,
            senderLastName: groupDetails.lastName,
            photoURL: groupDetails.photo,
            senderJID: xmpp.jid?.toString(),
            senderWalletAddress: groupDetails.walletAddress,
            roomJid: groupDetails.roomJid,
            isSystemMessage: false,
            tokenAmount: 0,
            quickReplies: [],
            notDisplayedValue: groupDetails.notDisplayedValue ?? "",
          }),
          xml("body", {}, message),
        ) : xml(
          'message',
          { type: 'chat', to: jid },
          xml('body', {}, message),
        );

        xmpp.send(msg)
          .then(() => {
            console.log(`Message sent successfully to ${jid}`);
          })
          .catch(error => {
            console.error('Error sending XMPP message:', error);
          });
      } else {
        console.error('XMPP client is not initialized.');
      }
    },
    receiveMessage: (state, action) => {
      const {
        chatType,
        from,
        to,
        message,
        senderFirstName,
        senderLastName,
        senderJID,
        senderWalletAddress,
        roomJid,
        isSystemMessage,
        tokenAmount,
        quickReplies,
        notDisplayedValue,
      } = action.payload;

      const newMessage = {
        id: `${new Date().getTime()}_${from}_${to}`,
        from,
        to,
        message,
        senderFirstName,
        senderLastName,
        senderJID,
        senderWalletAddress,
        roomJid,
        isSystemMessage,
        tokenAmount,
        quickReplies,
        notDisplayedValue,
      };

      if (!state[chatType].messageIds.includes(newMessage.id)) {
        if (chatType === 'group') {
          state[chatType].messages.push(newMessage);
        } else {
          state[chatType].messages.unshift(newMessage);
        }
        state[chatType].messageIds.push(newMessage.id);
      }
    },
    setXmppConnected: (state, action) => {
      state.xmppConnected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatHistory.pending, (state, action) => {
        const { chatType } = action.meta.arg;
        state[chatType].loading = true;
      })
      .addCase(fetchChatHistory.fulfilled, (state, action) => {
        const { chatType, messages, isLoadingMore } = action.payload;
        state[chatType].loading = false;
        
        if (isLoadingMore) {
          // Filter out duplicates when adding more messages
          const newMessages = messages.filter(msg => !state[chatType].messageIds.includes(msg.id));
          state[chatType].messages = [...state[chatType].messages, ...newMessages];
          state[chatType].messageIds = [...state[chatType].messageIds, ...newMessages.map(msg => msg.id)];
        } else {
          state[chatType].messages = messages;
          state[chatType].messageIds = messages.map(msg => msg.id);
        }
        
        state[chatType].offset += messages.length;
        state[chatType].hasMoreMessages = messages.length === state[chatType].limit;
      })
      .addCase(fetchChatHistory.rejected, (state, action) => {
        const { chatType } = action.meta.arg;
        state[chatType].loading = false;
        console.error('Failed to fetch chat history:', action.payload);
      })
      .addCase(sendNotification.rejected, (state, action) => {
        console.error('Failed to send notification:', action.payload);
      });
  },
});

export const { toggleSidebar, updateSidebarType, selectConversation, sendMessage, receiveMessage, setXmppConnected } = slice.actions;
export default slice.reducer;
