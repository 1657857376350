const { default: styled } = require("@emotion/styled");
const { Box } = require("@mui/material");

const ScrollContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  scrollbarWidth: 'thin', /* Firefox */
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}`, /* Firefox */
  '&::-webkit-scrollbar': {
    width: '8px',
    transition: 'opacity 0.3s',
    opacity: 0,
  },
  '&:hover::-webkit-scrollbar': {
    opacity: 1,
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

export default ScrollContainer;