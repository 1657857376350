import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  isHost: false,
  isViewer: false,
  selectedStreamId: '',
  hostRoomId: '',
  hostModalOpen: false,    // Manage host modal state
  viewerModalOpen: false,  // Manage viewer modal state
  isModalOpen: false,
  liveStreams: [],
  hostId: '',
  loading: false,
  error: null,
};

// Thunk to fetch live streams from the API
export const fetchLiveStreams = createAsyncThunk(
  'stream/fetchLiveStreams',
  async (_, { getState }) => {
    const { auth } = getState();  // Assuming token is stored in auth state
    const token = auth?.token;

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feeds/popular?page=1&limit=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("livestream data",response);
    
    return response.data.livestreams; // Only return the livestreams array
  }
);

const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    startStream: (state) => {
      state.isHost = true;
      state.hostModalOpen = true;  // Open host modal when stream starts
      state.isModalOpen = true;
    },
    joinStream: (state, action) => {
      state.isViewer = true;
      state.selectedStreamId = action.payload;
      state.viewerModalOpen = true;  // Open viewer modal when stream joins
      state.isModalOpen = true;
    },
    endStream: (state) => {
      state.isHost = false;
      state.isViewer = false;
      state.selectedStreamId = '';
      state.hostRoomId = '';
      state.hostId = '';
    },
    updateLiveStreams: (state, action) => {
      state.liveStreams = action.payload;
    },
    closeHostModal: (state) => {
      state.hostModalOpen = false;
    },
    closeViewerModal: (state) => {
      state.viewerModalOpen = false;
    },
    closeIsModalOpen: (state) => {
      state.isModalOpen = false;
    },
    setHostRoomId: (state, action) => {
      state.hostRoomId = action.payload;
    },
    setHostId: (state, action) => {
      state.hostId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveStreams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveStreams.fulfilled, (state, action) => {
        state.liveStreams = action.payload;  // Update the live streams in the state
        state.loading = false;
      })
      .addCase(fetchLiveStreams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
  startStream, 
  joinStream, 
  endStream, 
  updateLiveStreams, 
  closeHostModal, 
  closeViewerModal,
  closeIsModalOpen,
  setHostRoomId,
  setHostId 
} = streamSlice.actions;

export default streamSlice.reducer;
