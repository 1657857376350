import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { RHFTextField } from "../../components/hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { fetchGroups } from "state/groupSlice"; // Import the thunk

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateGroupForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.auth.followings); // Get users from the Redux store
  const authToken = useSelector((state) => state.auth.token); // Get auth token from the Redux store
  const currentUserId = useSelector((state) => state.auth.user.id); // Get current user ID

  const [error, setError] = useState(null); // State to manage errors
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to manage Snackbar visibility

  // Remove the current user from the users list
  const filteredUsers = users.filter((user) => user.id !== currentUserId);

  const NewGroupSchema = Yup.object().shape({
    title: Yup.string()
    .required("Title is required")
    .max(20, "Title must not exceed 20 characters"), // Added max validation rule
    members: Yup.array().min(2, "Must have at least 2 members"),
  });

  const defaultValues = {
    title: "",
    members: [],
  };

  const methods = useForm({
    resolver: yupResolver(NewGroupSchema),
    defaultValues,
  });

  const {
    reset,
    setError: setFormError,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const groupData = {
        roomName: data.title,
        memberIds: data.members, // Array of selected user IDs
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`, 
        },
        body: JSON.stringify(groupData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Group created successfully:", responseData);

      // Fetch the updated groups
      dispatch(fetchGroups({ userId: currentUserId, token: authToken }));

      // Optionally, reset the form or handle success state here
      reset();
      handleClose();
    } catch (error) {
      console.error("Error creating group:", error);
      setError(error.message); // Set error message
      setOpenSnackbar(true); // Show Snackbar
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="title" label="Title" />
        <Autocomplete
          multiple
          freeSolo
          options={filteredUsers}
          getOptionLabel={(option) => option.username}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <ListItemAvatar>
                <Avatar src={option.image} />
              </ListItemAvatar>
              <ListItemText primary={option.username} />
            </ListItem>
          )}
          onChange={(event, value) => {
            setValue("members", value.map(user => user.id)); // Store selected user IDs
          }}
          renderInput={(params) => (
            <RHFTextField {...params} name="members" label="Members" />
          )}
        />
        <Stack
          spacing={2}
          direction={"row"}
          alignItems="center"
          justifyContent={"end"}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Create
          </Button>
        </Stack>
      </Stack>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
};

const CreateGroup = ({ open, handleClose }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ p: 4 }}
    >
      <DialogTitle>{"Create New Group"}</DialogTitle>

      <DialogContent sx={{ mt: 4 }}>
        {/* Create Group Form */}
        <CreateGroupForm handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateGroup;
