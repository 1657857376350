import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { RHFTextField } from "../../components/hook-form";
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddMemberForm = ({ groupId, handleClose, currentMembers }) => {
  const users = useSelector((state) => state.auth.followings);
  const authToken = useSelector((state) => state.auth.token);

  // Filter out current members
  const availableUsers = users.filter(user => !currentMembers.includes(user.username));

  const AddMemberSchema = Yup.object().shape({
    member: Yup.object().required("Member is required"),
  });

  const defaultValues = {
    member: null,
  };

  const methods = useForm({
    resolver: yupResolver(AddMemberSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
  } = methods;

  // Watch the selected member
  const selectedMember = watch("member");

  // State for error messages
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/groups/${groupId}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ userId: data.member.id }), // Updated key to match the server's expected body
      });

      if (!response.ok) {
        const errorResponse = await response.text(); // Capture error message
        setErrorMessage(`Error: ${errorResponse}`);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Member added successfully:", responseData);

      reset();
      handleClose();
    } catch (error) {
      console.error("Error adding member:", error.message);
      setErrorMessage(error.message);
    }
  };

  return (
    <Dialog open onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Add Member</DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} padding={5}>
            <Autocomplete
              options={availableUsers}
              getOptionLabel={(option) => option.username}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                  <ListItemAvatar>
                    <Avatar src={option.image || ''} /> {/* Ensure default src */}
                  </ListItemAvatar>
                  <ListItemText primary={option.username} />
                </ListItem>
              )}
              onChange={(event, value) => setValue("member", value)}
              renderInput={(params) => (
                <RHFTextField {...params} name="member" label="Select Member" />
              )}
            />
            <Stack
              spacing={2}
              direction={"row"}
              alignItems="center"
              justifyContent={"end"}
            >
              <Button onClick={handleClose} color="inherit">Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!selectedMember} // Disable if no member selected
              >
                Add Member
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>
      {/* Snackbar for error messages */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddMemberForm;
