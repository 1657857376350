import PostWidget from "./PostWidget";
import { CircularProgress, Typography, Box, useTheme } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material";

const ProfilePostsWidget = ({ fetchUserDataLoading, fetchUserDataError, user }) => {
  const { palette } = useTheme();

  if (fetchUserDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (fetchUserDataError) {
    return (
      <Typography variant="body1" color="error">
        {fetchUserDataError}
      </Typography>
    );
  }

  if (!user || !user.posts || user.posts.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
        textAlign="center"
        bgcolor={palette.background.default}
        borderRadius="8px"
        boxShadow={3}
        p="2rem"
        m="2rem 0"
      >
        <SentimentDissatisfied
          style={{ fontSize: 100, color: palette.primary.main }}
        />
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ mt: 2 }}
        >
          No posts available.
        </Typography>
      </Box>
    );
  }

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getPostImageUrl = (content) => {
    if (isJSON(content)) {
      const parsedContent = JSON.parse(content);
      if (Array.isArray(parsedContent) && parsedContent.length > 0) {
        return parsedContent[0]?.url || "";
      }
    }
    return content; // Return the content as is if it's not JSON
  };

  return (
    <Box>
      {user.posts.map(
        ({
          id,
          title,
          content,
          author,
          _count,
        }) => {
          const postImageUrl = getPostImageUrl(content);
          
          return (
            <PostWidget
              key={id}
              username={author.username}
              userId={author.id}
              postId={id}
              title={title}
              name={`${author?.firstName || ""} ${author?.lastName || ""}`.trim()}
              postImage={postImageUrl}
              userPicturePath={author?.image || ""}
              likes={_count?.likedBy || 0}
              comments={_count?.comments || 0}
            />
          );
        }
      )}
    </Box>
  );
};

export default ProfilePostsWidget;
