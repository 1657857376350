import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Alert,
  Divider,
} from "@mui/material";
import { DeleteOutlined, Close } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/system";
import { updateUser } from "state";

const UpdateProfileModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const { palette } = useTheme();
  const medium = palette.neutral.medium;
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    mobileNumber: "",
    bio: "",
    image: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageUploadError, setImageUploadError] = useState("");

  useEffect(() => {
    if (user) {
      setFormValues({
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        mobileNumber: user.mobileNumber,
        bio: user.bio,
        image: user.image,
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageUpload = async (file) => {
    setUploading(true);
    setImageUploadError("");
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${baseURL}/upload/multiple`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        if (data && data.length > 0) {
          setFormValues((prevValues) => ({
            ...prevValues,
            image: data[0].url,
          }));
        }
      } else {
        setImageUploadError("Something went wrong, try again.");
      }
    } catch (error) {
      setImageUploadError(error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${baseURL}/users`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formValues),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        dispatch(updateUser(formValues));
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box sx={headerStyle}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={contentStyle}>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Username"
              name="username"
              value={formValues.username}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Mobile Number"
              name="mobileNumber"
              value={formValues.mobileNumber}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Bio"
              name="bio"
              value={formValues.bio}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Box
              border={`1px solid ${medium}`}
              borderRadius="5px"
              mt="1rem"
              p="1rem"
            >
              <Box
                border={`2px dashed ${palette.primary.main}`}
                p="1rem"
                width="100%"
                sx={{ "&:hover": { cursor: "pointer" } }}
                onDrop={(e) => {
                  e.preventDefault();
                  const file = e.dataTransfer.files[0];
                  handleImageUpload(file);
                }}
                onDragOver={(e) => e.preventDefault()}
              >
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  id="image-input"
                  onChange={(e) => handleImageUpload(e.target.files[0])}
                />
                <label htmlFor="image-input" style={{ cursor: "pointer" }}>
                  {!uploading && !formValues.image && (
                    <p>Drag & drop an image here, or click to select one</p>
                  )}
                </label>
                {uploading && <CircularProgress />}
                {formValues.image && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{formValues.image.split("/").pop()}</Typography>
                    <IconButton
                      onClick={() =>
                        setFormValues({ ...formValues, image: "" })
                      }
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {imageUploadError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {imageUploadError}
                </Alert>
              )}
            </Box>
            <Divider sx={{ margin: "1.25rem 0" }} />
            {loading ? (
                
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Update Profile
              </Button>
            )}
            {success && (
              <Typography color="success.main">
                Profile updated successfully
              </Typography>
            )}
            {error && <Typography color="error.main">{error}</Typography>}
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: "90vh",
  display: "flex",
  flexDirection: "column",
};

const headerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  p: 1,
};

const contentStyle = {
  overflowY: "auto",
  p: 2,
  flex: 1,
};

export default UpdateProfileModal;
