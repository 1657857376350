import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import authReducer from "./state";
import sidebarReducer from "./state/chat";
import groupReducer from "./state/groupSlice";
import callReducer from "./state/call";
import groupCallReducer from "./state/groupCall";
import streamReducer from "./state/streamSlice";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "util/errorBoundary";

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  groups: groupReducer, // Add the group slice here
  call: callReducer,
  groupcall: groupCallReducer,
  livestream: streamReducer,
});

// Persist configuration with blacklist
const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: ["call", "groupcall", "livestream"], // Blacklist the call slice
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor for managing the persistence
export const persistor = persistStore(store);

// Render the application with Redux and Redux Persist
const root = ReactDOM.createRoot(document.getElementById("root"));
// Add this before root.render()
// if (typeof window !== 'undefined') {
//   window.addEventListener('error', (event) => {
//     if (event.message === 'ResizeObserver loop limit exceeded') {
//       console.warn('ResizeObserver error occurred, but was prevented from crashing the app');
//       event.preventDefault();
//       event.stopPropagation();
//     }
//   });
// }
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
