import { Box } from "@mui/material";

const UserImage = ({ image, size = "60px" }) => {
  const defaultImage = "/assets/profile.jpg"; // Replace with the actual path to your default image

  return (
    <Box
      component="img"
      alt="user"
      src={image || defaultImage}
      width={size}
      height={size}
      style={{ objectFit: "cover", borderRadius: "50%" }}
    />
  );
};

export default UserImage;

