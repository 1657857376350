import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { useSocket } from "../../context/SocketProvider";
import { useDispatch, useSelector } from "react-redux";
import ViewerPeerService from "../../services/ViewerPeerService";
import {
  closeIsModalOpen,
  closeViewerModal,
  endStream,
  setHostId,
} from "../../state/streamSlice";
import { ExitToApp, Fullscreen, FullscreenExit } from "@mui/icons-material";

const ViewerStream = () => {
  const [remoteStream, setRemoteStream] = useState(null);
  const [connectionError, setConnectionError] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const socket = useSocket();
  const currentUser = useSelector((state) => state.auth.user);
  const hostId = useSelector((state) => state.livestream.hostId);
  const dispatch = useDispatch();
  const remoteVideoRef = useRef(null);
  const hostIdRef = useRef(hostId);
  const fullScreenRef = useRef(null);

  useEffect(() => {
    hostIdRef.current = hostId;
    console.log("host id changed", hostId);
  }, [hostId]);

    // Fullscreen change handler
    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };
  
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      return () => {
        document.removeEventListener("fullscreenchange", handleFullScreenChange);
      };
    }, []);
  
    // Toggle fullscreen function
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        fullScreenRef.current.requestFullscreen();
        setIsFullScreen(true);
      } else {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    };
  

  useEffect(() => {
    ViewerPeerService.setRemoteStreamCallback((stream) => {
      setRemoteStream(stream);
    });

    ViewerPeerService.setIceCandidateCallback((candidate) => {
      console.log("host id", hostIdRef.current);
      socket.emit("livesteam-ice-candidate", {
        targetId: hostIdRef.current,
        candidate,
      });
    });

    if (socket) {
      socket.on("livesteam-offer", async (payload) => {
        dispatch(setHostId(payload.hostId));
        const answer = await ViewerPeerService.handleOffer(payload.offer);
        if (answer) {
          socket.emit("livesteam-answer", { hostId: payload.hostId, answer });
        }
      });

      socket.on("livesteam-ice-candidate", (payload) => {
        console.log("received ice candidate from host", payload);
        ViewerPeerService.addIceCandidate(payload.candidate);
      });

      socket.on("host-disconnected", () => {
        console.log("host disconnected");
        handleLeaveStream();
      });
    }

    return () => {
      ViewerPeerService.closeConnection();
      if (socket) {
        socket.off("livesteam-offer");
        socket.off("livesteam-ice-candidate");
        socket.off("host-disconnected");
      }
    };
  }, [socket, currentUser?.id, dispatch]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const handleLeaveStream = () => {
    setConnectionError("You have left the stream.");
    dispatch(closeViewerModal());
    dispatch(endStream());
    dispatch(closeIsModalOpen());
    ViewerPeerService.closeConnection();
    if (socket) {
      socket.emit("viewer-left-stream", { hostId: hostIdRef.current });
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: isFullScreen ? 0 : 2,
    height: isFullScreen ? "100vh" : "auto",
    width: isFullScreen ? "100vw" : "auto",
    position: isFullScreen ? "fixed" : "relative",
    top: isFullScreen ? 0 : "auto",
    left: isFullScreen ? 0 : "auto",
    backgroundColor: isFullScreen ? "background.paper" : "transparent",
    zIndex: isFullScreen ? 9999 : "auto"
  };

  const videoContainerStyle = {
    position: "relative",
    width: isFullScreen ? "100%" : "100%",
    maxWidth: isFullScreen ? "none" : "640px",
    height: isFullScreen ? "calc(100vh - 80px)" : "auto",
    aspectRatio: isFullScreen ? "auto" : "16/9",
    borderRadius: isFullScreen ? 0 : "16px",
    overflow: "hidden"
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: isFullScreen ? "contain" : "cover",
    borderRadius: isFullScreen ? 0 : "16px",
  };

  const controlsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 1, sm: 2 }, // Smaller gap on mobile, larger on bigger screens
    marginTop: isFullScreen ? 0 : 2,
    padding: isFullScreen ? "20px 0" : 0,
    backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.5)" : "transparent",
    position: isFullScreen ? "fixed" : "relative",
    bottom: isFullScreen ? 0 : "auto",
    width: "100%",
    zIndex: 1000,
  };
  
  const iconButtonStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "50%",
    padding: { xs: "6px", sm: "10px" }, // Smaller padding on mobile
    color: "#555",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#000",
    },
  };
  
  const iconInnerStyle = {
    fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Smaller icons on mobile
  };

  return (
    <Box sx={containerStyle} ref={fullScreenRef}>
      {remoteStream ? (
        <Box sx={videoContainerStyle}>
          <video
            ref={remoteVideoRef}
            autoPlay
            playsInline
            controls={false}
            style={videoStyle}
          />
        </Box>
      ) : (
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ color: isFullScreen ? "white" : "inherit" }}
        >
          {connectionError || "Waiting for host to start the stream..."}
        </Typography>
      )}

      <Box sx={controlsStyle}>
        <IconButton
          sx={iconButtonStyle}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <FullscreenExit sx={iconInnerStyle} /> : <Fullscreen sx={iconInnerStyle} />}
        </IconButton>

        <IconButton
          sx={{
            ...iconButtonStyle,
            color: "white",
            backgroundColor: "red",
            "&:hover": { backgroundColor: "darkred" }
          }}
          onClick={handleLeaveStream}
        >
          <ExitToApp sx={iconInnerStyle} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ViewerStream;