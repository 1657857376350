import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchUsersList } from "../../state/thunks";

const FriendListWidget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { fetchUserDataLoading, fetchUserDataError, followers, followings, users, fetchUsersListLoading, fetchUsersListError, token } = useSelector((state) => state.auth);
  const [showFollowers, setShowFollowers] = useState(true);
  const [suggestedUsers, setSuggestedUsers] = useState([]);

  useEffect(() => {
    if (token) {
      dispatch(fetchUsersList());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (users.length > 0) {
      const currentUser = followers.concat(followings).map(user => user.username);
      setSuggestedUsers(users.filter(user => !currentUser.includes(user.username)));
    }
  }, [users, followers, followings]);

  const navigateToProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  return (
    <>
      <WidgetWrapper>
        <Box display="flex" justifyContent="space-between" mb="1rem">
          <Button
            variant={showFollowers ? "contained" : "outlined"}
            onClick={() => setShowFollowers(true)}
          >
            Followers
          </Button>
          <Button
            variant={!showFollowers ? "contained" : "outlined"}
            onClick={() => setShowFollowers(false)}
          >
            Followings
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" gap="1rem" mb="1rem">
          {fetchUserDataLoading && <CircularProgress />}
          {fetchUserDataError && (
            <Typography color="error">{fetchUserDataError}</Typography>
          )}
          {showFollowers && !fetchUserDataLoading && !fetchUserDataError && followers.length === 0 && (
            <Typography>No followers found.</Typography>
          )}
          {!showFollowers && !fetchUserDataLoading && !fetchUserDataError && followings.length === 0 && (
            <Typography>No followings found.</Typography>
          )}
          {!fetchUserDataLoading &&
            (showFollowers ? followers : followings).map((user) => (
              <Friend
                key={user.id}
                username={user.username}
                userPicturePath={user.image}
                onClick={() => navigateToProfile(user.username)}
              />
            ))}
        </Box>
      </WidgetWrapper>

      <WidgetWrapper mt="1rem">
        <Box>
          <Typography variant="h6" color={"red"} fontSize={"1rem"} textAlign={"center"}>Suggested Users</Typography>
          <br />
          {fetchUsersListLoading && <CircularProgress />}
          {fetchUsersListError && (
            <Typography color="error">{fetchUsersListError}</Typography>
          )}
          {!fetchUsersListLoading && !fetchUsersListError && suggestedUsers.length === 0 && (
            <Typography>No suggested users found.</Typography>
          )}
          <Box display="flex" flexDirection="column" gap="1rem">
            {!fetchUsersListLoading &&
              suggestedUsers.map((user) => (
                <Friend
                  key={user.id}
                  username={user.username}
                  userPicturePath={user.image}
                  userId={user.id}
                  onClick={() => navigateToProfile(user.username)}
                />
              ))}
          </Box>
        </Box>
      </WidgetWrapper>
    </>
  );
};

export default FriendListWidget;
