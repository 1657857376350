import { alpha } from "@mui/material";

const PRIMARY = {
  lighter: '#FCE7E7',
  light: '#F49797',
  main: '#EA1717',
  dark: '#B61212',
  darker: '#800D0D',
};
const SECONDARY = {
  lighter: '#D6E4FF', // Adjust based on existing theme secondary color
  light: '#84A9FF',   // Adjust based on existing theme secondary color
  main: '#3366FF',    // Adjust based on existing theme secondary color
  dark: '#1939B7',    // Adjust based on existing theme secondary color
  darker: '#091A7A',  // Adjust based on existing theme secondary color
};
const INFO = {
  lighter: '#D0F2FF', // Adjust based on existing theme info color
  light: '#74CAFF',   // Adjust based on existing theme info color
  main: '#1890FF',    // Adjust based on existing theme info color
  dark: '#0C53B7',    // Adjust based on existing theme info color
  darker: '#04297A',  // Adjust based on existing theme info color
};
const SUCCESS = {
  lighter: '#E9FCD4', // Adjust based on existing theme success color
  light: '#AAF27F',   // Adjust based on existing theme success color
  main: '#54D62C',    // Adjust based on existing theme success color
  dark: '#229A16',    // Adjust based on existing theme success color
  darker: '#08660D',  // Adjust based on existing theme success color
};
const WARNING = {
  lighter: '#FFF7CD', // Adjust based on existing theme warning color
  light: '#FFE16A',   // Adjust based on existing theme warning color
  main: '#FFC107',    // Adjust based on existing theme warning color
  dark: '#B78103',    // Adjust based on existing theme warning color
  darker: '#7A4F01',  // Adjust based on existing theme warning color
};
const ERROR = {
  lighter: '#FFE7D9', // Adjust based on existing theme error color
  light: '#FFA48D',   // Adjust based on existing theme error color
  main: '#FF4842',    // Adjust based on existing theme error color
  dark: '#B72136',    // Adjust based on existing theme error color
  darker: '#7A0C2E',  // Adjust based on existing theme error color
};

const GREY = {
  0: '#FFFFFF',
  100: '#F0F0F0',
  200: '#E0E0E0',
  300: '#C2C2C2',
  400: '#A3A3A3',
  500: '#858585',
  600: '#666666',
  700: '#4D4D4D',
  800: '#333333',
  900: '#1A1A1A',
  500_8: alpha('#666666', 0.08),
  500_12: alpha('#666666', 0.12),
  500_16: alpha('#666666', 0.16),
  500_24: alpha('#666666', 0.24),
  500_32: alpha('#666666', 0.32),
  500_48: alpha('#666666', 0.48),
  500_56: alpha('#666666', 0.56),
  500_80: alpha('#666666', 0.8),
};



const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'], // Adjust based on existing theme
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],   // Adjust based on existing theme
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],  // Adjust based on existing theme
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'], // Adjust based on existing theme
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],    // Adjust based on existing theme
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
