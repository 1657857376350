import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const ForgotPasswordModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${baseURL}/auth/forget-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) {
        setSuccess("Password reset link has been sent to your email.");
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setEmail("");
    setLoading(false);
    setError(null);
    setSuccess(null);
    handleClose(); // Close modal using parent callback
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        px={3}
      >
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          display="flex"
          flexDirection="column"
          gap="20px"
          width={isNonMobile ? "400px" : "100%"}
          maxWidth="100%"
          bgcolor="background.paper"
          p={3}
          borderRadius="8px"
          boxShadow={24}
        >
          <Typography variant="h4" textAlign="center">
            Forgot Password
          </Typography>
          <Typography variant="body1" textAlign="center" color="textSecondary">
            Enter your email to receive a password reset link.
          </Typography>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={handleInputChange}
            fullWidth
            required
          />
          {error && (
            <Typography variant="body2" color="error" textAlign="center">
              {error}
            </Typography>
          )}
          {success && (
            <Typography variant="body2" color="primary" textAlign="center">
              {success}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Send Reset Link"}
          </Button>
          <Typography
            onClick={handleCloseModal}
            sx={{
              textDecoration: "underline",
              textAlign: "center",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.dark,
              },
              mt: 2,
            }}
          >
            Close
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
