import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import { isTokenExpired, setLogout } from './state/index';
import HomePage from 'scenes/homePage';
import LoginPage from 'scenes/loginPage';
import ProfilePage from 'scenes/profilePage';
import Chat from 'scenes/Chat';
import Notifications from 'scenes/Notifications';
import Help from 'scenes/Help';
import Page404 from 'scenes/Chat/pages/Page404';
import Layout from 'Layout';
import GeneralApp from 'scenes/Chat/pages/dashboard/GeneralApp';
import Group from 'scenes/Chat/pages/dashboard/Group';
import ForgotPassword from 'scenes/forgetPass';
import GlobalFeedWidget from 'scenes/widgets/GlobalFeedWidget';
import { SocketProvider } from 'context/SocketProvider';
import useXmppClient from 'scenes/Chat/hooks/useXmppClient';
import InCallModal from 'scenes/Chat/callmodals/InCallModal';
import GroupCallModal from 'scenes/Chat/callmodals/GroupCallModal';
import StreamPage from 'scenes/liveStreamPage';
import { initializeFCM, setupMessageListener } from 'services/firebaseService';
import SinglePostPage from 'scenes/widgets/SinglePostPage';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  
  useEffect(() => {
    if (isTokenExpired(token)) {
      dispatch(setLogout());
    }
  }, [dispatch, token]);

  if (!token || isTokenExpired(token)) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  useXmppClient();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.auth.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const token = useSelector((state) => state.auth.token);
  const fcmToken = useSelector((state) => state.auth.fcmToken);

  useEffect(() => {
    const checkTokenInterval = setInterval(() => {
      
      if (token && isTokenExpired(token)) {
        dispatch(setLogout());
      }
    }, 60000); // Check every minute

    return () => clearInterval(checkTokenInterval);
  }, [dispatch, token]);

  useEffect(() => {
    const initializeFcmToken = async () => {
      try {
        if (token && fcmToken) {
          await initializeFCM(token, dispatch, fcmToken);
          console.log("FCM initialized successfully");
          setupMessageListener(token);
        }
      } catch (error) {
        console.error('Error initializing FCM on page load:', error);
        // Show a user-friendly error message
        toast.error('Failed to initialize notifications. Some features may be unavailable.');
      }
    };

    initializeFcmToken();
  }, [token, dispatch, fcmToken]);

  useEffect(() => {
    const handleResizeObserverError = (event) => {
      if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
        // Prevent the error from appearing
        event.stopImmediatePropagation();
        event.preventDefault();
        return false;
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('error', handleResizeObserverError, true);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', handleResizeObserverError, true);
    };
  }, []);

  return (
    <div className="app">
      <SocketProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <InCallModal />
            <GroupCallModal />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Layout />}>
                <Route path="home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="profile/:username" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path="/post/:postId" element={<ProtectedRoute><SinglePostPage /></ProtectedRoute>} />
                <Route path="chat" element={<ProtectedRoute><Chat /></ProtectedRoute>}>
                  <Route path="app" element={<GeneralApp />} />
                  <Route path="group" element={<Group />} />
                  <Route path="*" element={<Navigate to="/404" />} />
                </Route>
                <Route path="notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
                <Route path="globalfeed" element={<ProtectedRoute><GlobalFeedWidget /></ProtectedRoute>} />
                <Route path="streampage" element={<ProtectedRoute><StreamPage /></ProtectedRoute>} />
                {/* <Route path="help" element={<ProtectedRoute><Help /></ProtectedRoute>} /> */}
              </Route>
              <Route path="404" element={<Page404 />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </SocketProvider>
    </div>
  );
}

export default App;