import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Box, Typography, IconButton, Badge } from "@mui/material";
import { useSocket } from "../../context/SocketProvider";
import { useDispatch, useSelector } from "react-redux";
import { closeHostModal, closeIsModalOpen, endStream } from "../../state/streamSlice";
import LiveStreamPeerService from "../../services/LiveStreamPeerService";
import { ExitToApp, Fullscreen, FullscreenExit, Mic, MicOff, ScreenShare, StopScreenShare, Videocam, VideocamOff, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";

const HostStream = () => {
  const dispatch = useDispatch();
  const [localStream, setLocalStream] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [viewerCount, setViewerCount] = useState(0);
  const [screenSharing, setScreenSharing] = useState(false);
  const [isCameraAvailable, setIsCameraAvailable] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);
  const screenStreamRef = useRef(null);
  const fullScreenRef = useRef(null);
  
  const currentUser = useSelector((state) => state.auth.user);
  const { isHost, hostRoomId } = useSelector((state) => state.livestream);
  const socket = useSocket();

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullScreenRef.current.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (isHost) {
      startStream();
    }

    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
      LiveStreamPeerService.closeAllConnections();
    };
  }, [isHost]);

  useEffect(() => {
    if (videoRef.current && localStream) {
      videoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  const startWithScreenShare = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ 
        video: true,
        audio: true 
      });
      
      setLocalStream(screenStream);
      setIsStreaming(true);
      setScreenSharing(true);
      screenStreamRef.current = screenStream;
      
      // Handle screen sharing stop
      screenStream.getVideoTracks()[0].onended = () => {
        stopStream();
      };

      LiveStreamPeerService.setLocalStream(screenStream);
      return true;
    } catch (error) {
      console.error("Failed to start screen sharing:", error);
      return false;
    }
  };

  const startWithCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
      setIsStreaming(true);
      setIsCameraAvailable(true);
      LiveStreamPeerService.setLocalStream(stream);
      return true;
    } catch (error) {
      console.error("Failed to access camera:", error);
      setIsCameraAvailable(false);
      return false;
    }
  };

  const startStream = useCallback(async () => {
    try {
      // First try to start with camera
      const cameraSuccess = await startWithCamera();
      
      // If camera fails, automatically prompt for screen sharing
      if (!cameraSuccess) {
        const screenSuccess = await startWithScreenShare();
        if (!screenSuccess) {
          throw new Error("Both camera and screen sharing failed");
        }
      }
    } catch (error) {
      console.error("Failed to start stream:", error);
      toast.error("Failed to start stream");
      // Handle complete failure - maybe show an error message to user
    }
  }, []);

  const handleViewerConnected = useCallback(async (viewerId) => {
    try {
      console.log("viewer connected", viewerId);
      const peerConnection = LiveStreamPeerService.createPeerConnection(viewerId);
      const offer = await LiveStreamPeerService.createOffer(viewerId);
      socket.emit("livesteam-offer", { viewerId, offer });
      setViewerCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error(`Error handling viewer connection for ${viewerId}:`, error);
    }
  }, [socket]);

  const handleIceCandidate = useCallback(({ targetId, candidate }) => {
    console.log(`ice candidate received from viewer ${targetId}`, candidate);
    LiveStreamPeerService.addIceCandidate(targetId, candidate);
  }, []);

  const handleAnswer = useCallback(async ({ viewerId, answer }) => {
    await LiveStreamPeerService.setRemoteDescription(viewerId, answer);
  }, []);

  useEffect(() => {
    LiveStreamPeerService.setIceCandidateCallback((viewerId, candidate) => {
      socket.emit("livesteam-ice-candidate", {
        targetId: viewerId,
        candidate: candidate,
      });
    });

    if (localStream && socket) {
      socket.on("viewer-connected", handleViewerConnected);
      socket.on("livesteam-ice-candidate", handleIceCandidate);
      socket.on("livesteam-answer", handleAnswer);
    }

    return () => {
      if (socket) {
        socket.off("viewer-connected", handleViewerConnected);
        socket.off("livesteam-ice-candidate", handleIceCandidate);
        socket.off("livesteam-answer", handleAnswer);
      }
    };
  }, [handleViewerConnected, handleIceCandidate, handleAnswer, localStream, socket]);

  const stopStream = useCallback(() => {
    socket.emit("livesteam-drop", {
      livesteamId: hostRoomId,
      isHost: true,
      userId: currentUser?.id,
    });

    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    setIsStreaming(false);
    setLocalStream(null);
    dispatch(closeHostModal());
    dispatch(closeIsModalOpen());
    dispatch(endStream());
    setViewerCount(0);
    LiveStreamPeerService.closeAllConnections();
  }, [localStream, dispatch, socket, hostRoomId, currentUser?.id]);

  const toggleMic = useCallback(() => {
    setMicOn((prev) => !prev);
    localStream?.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
  }, [localStream]);

  const toggleVideo = useCallback(() => {
    setVideoOn((prev) => !prev);
    localStream?.getVideoTracks().forEach((track) => (track.enabled = !track.enabled));
  }, [localStream]);

  const toggleScreenShare = async () => {
    if (screenSharing) {
      // If camera is available, switch back to it
      if (isCameraAvailable) {
        const screenTrack = screenStreamRef.current.getVideoTracks()[0];
        screenTrack.stop();
        
        try {
          const webcamStream = await navigator.mediaDevices.getUserMedia({ video: true });
          const webcamTrack = webcamStream.getVideoTracks()[0];
          localStream.removeTrack(screenTrack);
          localStream.addTrack(webcamTrack);
          setLocalStream(localStream);
          LiveStreamPeerService.replaceTrackForAllPeers(webcamTrack);
          screenStreamRef.current = null;
          setScreenSharing(false);
        } catch (error) {
          console.error("Failed to switch back to camera:", error);
        }
      }  else {
        // If no camera, confirm with the user before stopping the stream
        const userConfirmed = window.confirm(
          "Stream will be stopped if you end Screen sharing. Do you want to proceed?"
        );
        
        if (userConfirmed) {
          // User confirmed, proceed to stop the stream
          stopStream();
        } else {
          // User canceled, do not stop screen sharing
          return;
        }
      }
    } else {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        const screenTrack = screenStream.getVideoTracks()[0];
        
        if (localStream) {
          const videoTrack = localStream.getVideoTracks()[0];
          localStream.removeTrack(videoTrack);
          localStream.addTrack(screenTrack);
          setLocalStream(localStream);
        } else {
          setLocalStream(screenStream);
        }
        
        LiveStreamPeerService.replaceTrackForAllPeers(screenTrack);
        screenStreamRef.current = screenStream;
        screenTrack.onended = () => toggleScreenShare();
        setScreenSharing(true);
      } catch (error) {
        console.error("Error sharing the screen:", error);
      }
    }
  };



  const containerStyle = {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    padding: isFullScreen ? 0 : 2,
    height: isFullScreen ? "100vh" : "auto",
    width: isFullScreen ? "100vw" : "auto",
    position: isFullScreen ? "fixed" : "relative",
    top: isFullScreen ? 0 : "auto",
    left: isFullScreen ? 0 : "auto",
    backgroundColor: isFullScreen ? "background.paper" : "transparent",
    zIndex: isFullScreen ? 9999 : "auto"
  };

  const videoContainerStyle = {
    position: "relative", 
    width: isFullScreen ? "100%" : "100%", 
    maxWidth: isFullScreen ? "none" : "640px",
    height: isFullScreen ? "calc(100vh - 80px)" : "auto",
    aspectRatio: isFullScreen ? "auto" : "16/9",
    borderRadius: isFullScreen ? 0 : "16px",
    overflow: "hidden"
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: isFullScreen ? "contain" : "cover",
    borderRadius: isFullScreen ? 0 : "16px",
  };

  const controlsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 1, sm: 2 }, // Smaller gap on mobile, larger on bigger screens
    marginTop: isFullScreen ? 0 : 2,
    padding: isFullScreen ? "20px 0" : 0,
    backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.5)" : "transparent",
    position: isFullScreen ? "fixed" : "relative",
    bottom: isFullScreen ? 0 : "auto",
    width: "100%",
    zIndex: 1000,
  };
  
  const iconButtonStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "50%",
    padding: { xs: "6px", sm: "10px" }, // Smaller padding on mobile
    color: "#555",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#000",
    },
  };
  
  const iconInnerStyle = {
    fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Smaller icons on mobile
  };
  

  return (
    <Box sx={containerStyle} ref={fullScreenRef}>
      <Box sx={videoContainerStyle}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={videoStyle}
        />
        {!videoOn && !screenSharing && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <Typography variant="h6" color="white">
              {isCameraAvailable ? "Camera is off" : "Camera not available"}
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={controlsStyle}>
        <Badge
          badgeContent={viewerCount}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              right: -3,
              top: 13,
              border: `2px solid ${isFullScreen ? 'transparent' : 'white'}`,
              padding: "0 4px",
            },
          }}
        >
          <Visibility sx={{ fontSize: 40, color: isFullScreen ? "white" : "#1976d2" }} />
        </Badge>

        {isCameraAvailable && (
          <IconButton 
            sx={{...iconButtonStyle, 
              backgroundColor: isFullScreen ? 'rgba(255, 255, 255, 0.2)' : '#f0f0f0',
              color: isFullScreen ? 'white' : '#555'
            }} 
            onClick={toggleVideo}
          >
            {videoOn ? <Videocam sx={iconInnerStyle} /> : <VideocamOff sx={iconInnerStyle} />}
          </IconButton>
        )}
        
        <IconButton 
          sx={{...iconButtonStyle, 
            backgroundColor: isFullScreen ? 'rgba(255, 255, 255, 0.2)' : '#f0f0f0',
            color: isFullScreen ? 'white' : '#555'
          }} 
          onClick={toggleMic}
        >
          {micOn ? <Mic sx={iconInnerStyle} /> : <MicOff sx={iconInnerStyle} />}
        </IconButton>

        <IconButton 
          sx={{...iconButtonStyle, 
            backgroundColor: isFullScreen ? 'rgba(255, 255, 255, 0.2)' : '#f0f0f0',
            color: isFullScreen ? 'white' : '#555'
          }} 
          onClick={toggleScreenShare}
        >
          {screenSharing ? <StopScreenShare sx={iconInnerStyle} /> : <ScreenShare sx={iconInnerStyle} />}
        </IconButton>

        <IconButton 
          sx={{...iconButtonStyle, 
            backgroundColor: isFullScreen ? 'rgba(255, 255, 255, 0.2)' : '#f0f0f0',
            color: isFullScreen ? 'white' : '#555'
          }} 
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <FullscreenExit sx={iconInnerStyle} /> : <Fullscreen sx={iconInnerStyle} />}
        </IconButton>
        
        <IconButton 
          sx={{ 
            ...iconButtonStyle, 
            color: "white", 
            backgroundColor: "red", 
            "&:hover": { backgroundColor: "darkred" } 
          }} 
          onClick={stopStream}
        >
          <ExitToApp sx={iconInnerStyle} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HostStream;