import { createAsyncThunk } from "@reduxjs/toolkit";

const base_url = process.env.REACT_APP_BASE_URL;

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async (_, { getState, rejectWithValue }) => {
    const { auth: { user, token } } = getState();
    if (!token || !user) {
      return rejectWithValue("Missing token or user information");
    }
    try {
      const response = await fetch(`${base_url}/users/${user.username}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPosts = createAsyncThunk(
  "auth/fetchPosts",
  async (_, { getState, rejectWithValue }) => {
    const { auth: { token } } = getState();
    if (!token) {
      console.log("Token missing");
      return rejectWithValue("Missing token information");
    }
    try {
      const response = await fetch(`${base_url}/posts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUsersList = createAsyncThunk(
  "auth/fetchUsersList",
  async (_, { getState, rejectWithValue }) => {
    const { auth: { token } } = getState();

    if (!token) {
      return rejectWithValue("Missing token information");
    }
    try {
      const response = await fetch(`${base_url}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users list');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

