import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const MessageModal = ({ open, handleClose, message, isError }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Typography
          variant="h6"
          color={isError ? "error.main" : "success.main"}
          mb={2}
        >
          {message}
        </Typography>
        <Button variant="contained" onClick={handleClose} fullWidth>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default MessageModal;
