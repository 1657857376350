import {
  Box,
  IconButton,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { ArchiveBox, CircleDashed, MagnifyingGlass } from "phosphor-react";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Search, SearchIconWrapper, StyledInputBase } from "scenes/Chat/components/Search";
import ChatElement from "scenes/Chat/components/ChatElement";
import ScrollContainer from "scenes/Chat/components/Scrollbar";

const Chats = () => {
  const theme = useTheme();
  const users = useSelector((state) => state.auth.followings);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) => 
    user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        position: "relative",
        width: 320,
        height: "calc(100vh - 80px)",
        backgroundColor:
          theme.palette.mode === "light"
            ? "#F8FAFF"
            : theme.palette.background.default,
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack p={3} spacing={2} sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography variant="h5">Chats</Typography>
          <IconButton>
            <CircleDashed />
          </IconButton>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Search>
            <SearchIconWrapper>
              <MagnifyingGlass color="#709CE6" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={handleSearch}
            />
          </Search>
        </Stack>
        <Stack
          spacing={2}
          direction="column"
          sx={{ flexGrow: 1, overflow: "hidden", height: "100%" }}
        >
          <ScrollContainer>
            <Stack spacing={2.4}>
              <Typography variant="subtitle2" sx={{ color: "#676767" }}>
                All Chats
              </Typography>
              {filteredUsers.map((user) => (
                <ChatElement
                  key={user.id}
                  id={user.id}
                  name={user.username}
                  img={user.image}
                  msg="" // No message yet
                  time="" // No time yet
                  unread={0} // No unread messages yet
                  online={true} // Assuming all users are online for now
                  chatType="personal"
                />
              ))}
            </Stack>
          </ScrollContainer>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Chats;
