import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/groups/users/${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch groups');
      }

      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchGroupMembers = createAsyncThunk(
  'groups/fetchGroupMembers',
  async ({ groupId, token }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/groups/${groupId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch group members');
      }

      const data = await response.json();
      console.log(data);
      return {
        groupId,
        owner: data.owners.map(owner => owner.user.username),
        admins: data.admins.map(admin => admin.user.username),
        members: data.members.map(member => member.user.username)
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  groups: [],
  members: {},
  status: 'idle',
  error: null,
};

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.groups = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchGroupMembers.fulfilled, (state, action) => {
        console.log(action.payload)
        const { groupId, owner, admins, members } = action.payload;
        state.members[groupId] = {
          owner,
          admins,
          members
        };
      })
      .addCase(fetchGroupMembers.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default groupSlice.reducer;
