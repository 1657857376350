class PeerService {
  constructor() {
      this.peer = null; // Initialize peer as null initially
      this.onRemoteStream = null;
  }

  createPeerConnection() {
      if (!this.peer) {
          this.peer = new RTCPeerConnection({
              iceServers: [
                  {
                      urls: [
                          "stun:stun.l.google.com:19302",
                      ],
                  },
              ],
          });
          console.log("Peer connection created.");

          // Handle ontrack event to add remote stream for each peer
      this.peer.ontrack = (event) => {
        const remoteStream = event.streams[0];
        console.log("Received remote track from user:", remoteStream);
        if (this.onRemoteStream) {
            this.onRemoteStream(remoteStream); // Trigger callback when remote track is received
          }
      };

      }
  }

  addLocalStream(stream) {
      // Ensure that peer is initialized before adding tracks
      if (!this.peer) {
          console.error("Peer connection is not initialized. Cannot add stream.");
          return;
      }
      
      console.log("peer js stream", stream);
      if (stream && stream.getTracks) {
          stream.getTracks().forEach(track => {
              this.peer.addTrack(track, stream);
              console.log("Track added:", track);
          });
      } else {
          console.error("Invalid stream. No tracks to add.");
      }
  }

  async getOffer() {
      if (!this.peer) {
          console.error("Peer connection is not initialized. Cannot create offer.");
          return null;
      }
      const offer = await this.peer.createOffer();
      await this.peer.setLocalDescription(new RTCSessionDescription(offer));
      return offer;
  }

  async getAnswer(offer) {
      if (!this.peer) {
          console.error("Peer connection is not initialized. Cannot create answer.");
          return null;
      }
      await this.peer.setRemoteDescription(offer);
      const ans = await this.peer.createAnswer();
      await this.peer.setLocalDescription(new RTCSessionDescription(ans));
      return ans;
  }

  async setRemoteDescription(ans) {
      if (!this.peer) {
          console.error("Peer connection is not initialized. Cannot set remote description.");
          return;
      }
      await this.peer.setRemoteDescription(new RTCSessionDescription(ans));
  }

  async addIceCandidate(candidate){
    if(this.peer){
      try{
        await this.peer.addIceCandidate(new RTCIceCandidate(candidate));
        console.log("added received ice candidate",candidate);
      }catch(error){
        console.log("error adding ice candidate:",error);
        
      }
    }
  }

  setRemoteStreamCallback(callback) {
    this.onRemoteStream = callback;
  }

  closeConnection() {
      if (this.peer) {
          this.peer.close(); // Close the peer connection
          this.peer = null; // Clean up the peer connection
          console.log("Peer connection closed.");
      }
  }
}

export default new PeerService();
