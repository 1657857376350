import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import googlelogo from "../../assets/google.png";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Grid,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { LoginSocialFacebook, LoginSocialTwitter } from "reactjs-social-login";
import {
  FacebookLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";

import { setLogin } from "state";
import ForgotPasswordModal from "scenes/widgets/ForgotPasswordModel";
import { initializeFCM, setupMessageListener } from "services/firebaseService";

const GoogleLoginButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    fullWidth
    sx={{
      height: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "0 12px",
      backgroundColor: "#DBDFE1",
      color: "#000000",
      border: "none",
      borderRadius: "4px",
      fontSize: "16px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F1F1F1",
      },
    }}
  >
    <img
      src={googlelogo} // Make sure to add a Google icon image
      alt="Google logo"
      style={{ width: "30px", height: "30px", marginRight: "10px" }}
    />
    Sign in with Google
  </Button>
);

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const [signUploading, setSignUploading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    mobileNumber: "",
    countryCode: "",
    password: "",
  });
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";

  // API URL
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    // Clear the error for this field when the user starts typing
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handlePhoneChange = (value) => {
    setFormValues((prevFormData) => ({
      ...prevFormData,
      mobileNumber: value,
    }));
    // Clear the error for mobile number when the user changes it
    if (errors.mobileNumber) {
      setErrors({
        ...errors,
        mobileNumber: null,
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const validateForm = () => {
    let formErrors = {};
    if (isRegister) {
      if (!formValues.firstName.trim()) {
        formErrors.firstName = "First Name is required";
      }
      if (!formValues.lastName.trim()) {
        formErrors.lastName = "Last Name is required";
      }
      if (!formValues.username.trim()) {
        formErrors.username = "Username is required";
      } else if (formValues.username.length > 15) {  // Validate max 15 chars for username
        formErrors.username = "Username must be at most 15 characters long";
      }
      if (!formValues.mobileNumber) {
        formErrors.mobileNumber = "Mobile number is required";
      }
    }
    if (!formValues.email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      formErrors.email = "Email is not valid";
    }
    if (!formValues.password.trim()) {
      formErrors.password = "Password is required";
    } else if (formValues.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters long";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const register = async () => {
    setSignUploading(true);
    try {
      const response = await fetch(`${baseURL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      const data = await response.json();
      if (response.ok) {
        setPageType("login");
      } else {
        setLoginLoading(false);
        showSnackbar(data.message);
      }
    } catch (error) {
      showSnackbar("Something went wrong. Please try again.");
    }
    setSignUploading(false);
  };

  const login = async () => {
    setLoginLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formValues.email,
          password: formValues.password,
        }),
      });
      const resObj = await response.json();
      if (response.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
        await initializeFCM(resObj.data.access_token, dispatch);
        setupMessageListener(resObj.data.access_token);
      } else {
        setLoginLoading(false);
        showSnackbar(resObj.message);
      }
    } catch (error) {
      showSnackbar("Something went wrong. Please try again.");
    }
    setLoginLoading(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isLogin) await login();
      if (isRegister) await register();
    }
  };

  const handleGoogleSuccess = async (response) => {
    console.log("google response", response);

    try {
      const backendResponse = await fetch(`${baseURL}/auth/google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: response.credential }),
      });
      const resObj = await backendResponse.json();
      console.log("google data", resObj);

      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
        await initializeFCM(resObj.data.access_token, dispatch);
        setupMessageListener(resObj.data.access_token);
      } else {
        console.error("Backend error:", resObj.message);
        showSnackbar("Google login failed. Please try again.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      showSnackbar("Google login failed. Please try again.");
    }
  };

  const handleFacebookSuccess = async (response) => {
    try {
      console.log("Facebook response:", response.data.accessToken);

      const backendResponse = await fetch(`${baseURL}/auth/facebook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: response.data.accessToken }),
      });

      const resObj = await backendResponse.json();
      console.log("Backend response status:", backendResponse.status);
      console.log("Backend response body:", resObj);

      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
        await initializeFCM(resObj.data.access_token, dispatch);
        setupMessageListener(resObj.data.access_token);
      } else {
        console.error("Backend error:", resObj.message);
        showSnackbar("Facebook login failed. Please try again.");
      }
    } catch (error) {
      console.error("Facebook login error:", error);
      showSnackbar("Facebook login failed. Please try again.");
    }
  };

  const handleTwitterSuccess = async (response) => {
    try {
      console.log("Twitter response:", response);
      const backendResponse = await fetch(`${baseURL}/auth/twitter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: response.oauth_token,
          token_secret: response.oauth_token_secret,
        }),
      });
      const resObj = await backendResponse.json();
      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
        await initializeFCM(resObj.data.access_token, dispatch);
        setupMessageListener(resObj.data.access_token);
      } else {
        showSnackbar("Twitter login failed. Please try again.");
      }
    } catch (error) {
      console.error("Twitter login error:", error);
      showSnackbar("Twitter login failed. Please try again.");
    }
  };

  // const handlelogingoogle = async (response) => {
  //   try {
  //     const accessToken = response.access_token;
      
  //     // Since id_token is not in response, this is to ensure we request it
  //     const idToken = response.id_token; // Might now be available after changing scope and flow
  
  //     if (idToken) {
  //       console.log("ID token (credentials):", idToken);
  //     } else {
  //       console.log("ID token is not available.");
  //     }
  
  //     // Fetch user info from Google
  //     const userInfoResponse = await fetch(
  //       "https://www.googleapis.com/oauth2/v3/userinfo",
  //       {
  //         method: "GET",
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     );
  
  //     if (userInfoResponse.ok) {
  //       const userInfo = await userInfoResponse.json();
  //       console.log("Google user data:", userInfo);
  //     } else {
  //       console.log("Failed to retrieve user info");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Google data", error);
  //   }
  // };
  
  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: handlelogingoogle,
  //   onError: (error) => {
  //     console.error("Google login error:", error);
  //     showSnackbar("Google login failed. Please try again.");
  //   },
  //   scope: "openid", // Make sure 'openid' scope is there
  // });
  
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {isRegister && (
            <>
              <TextField
                label="First Name"
                onChange={handleInputChange}
                value={formValues.firstName}
                name="firstName"
                sx={{ gridColumn: "span 2" }}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <TextField
                label="Last Name"
                onChange={handleInputChange}
                value={formValues.lastName}
                name="lastName"
                sx={{ gridColumn: "span 2" }}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
              <TextField
                label="username"
                onChange={handleInputChange}
                value={formValues.username}
                name="username"
                sx={{ gridColumn: "span 2" }}
                error={!!errors.username}
                helperText={errors.username}
              />
              <Box sx={{ gridColumn: "span 2" }}>
                <PhoneInput
                  placeholder="Your Phone Number"
                  id="mobileNumber"
                  country={"us"}
                  value={formValues.mobileNumber}
                  onChange={handlePhoneChange}
                  name="mobileNumber"
                  inputProps={{
                    required: true,
                  }}
                />
                {errors.mobileNumber && (
                  <Typography color="error" variant="body2">
                    {errors.mobileNumber}
                  </Typography>
                )}
              </Box>
            </>
          )}

          <TextField
            label="Email"
            onChange={handleInputChange}
            value={formValues.email}
            name="email"
            sx={{ gridColumn: "span 4" }}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            type="password"
            onChange={handleInputChange}
            value={formValues.password}
            name="password"
            sx={{ gridColumn: "span 4" }}
            error={!!errors.password}
            helperText={errors.password}
          />
        </Box>
        {isLogin && (
          <Typography
            onClick={() => setForgotPasswordOpen(true)}
            sx={{
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            Forgot Password?
          </Typography>
        )}
        <Box>
          <Button
            fullWidth
            type="submit"
            sx={{
              m: "2rem 0",
              p: "1rem",
              backgroundColor: palette.primary.main,
              color: palette.background.alt,
              "&:hover": { color: palette.primary.main },
            }}
            disabled={signUploading || loginLoading}
          >
            {isLogin ? "LOGIN" : "REGISTER"}
          </Button>
          {isLogin && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              {/* <GoogleLoginButton onClick={loginWithGoogle} /> */}
              <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={() => {
                    console.log("Login Failed");
                    setLoginError("Google login failed. Please try again.");
                  }}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      sx={{
                        width: "100% !important", // Full width of the parent container
                        maxWidth: "400px !important", // Optional: Limit maximum width
                        minWidth: "150px !important", // Optional: Ensure a minimum width
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px !important", // Ensure consistent padding
                        border: "1px solid #4285F4",
                        borderRadius: "4px",
                        color: "#4285F4",
                        backgroundColor: "transparent",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#f1f3f4",
                        },
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                        alt="Google logo"
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      Continue with Google
                    </Button>
                  )}
                />


              {/* <Box sx={{ height: "48px" }}> */}
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={handleFacebookSuccess}
                  onReject={(error) => {
                    console.error("Facebook login error:", error);
                    showSnackbar("Facebook login failed. Please try again.");
                  }}
                >
                  <FacebookLoginButton style={{ height: "48px" }} />
                </LoginSocialFacebook>
              {/* </Box> */}

              {/* <Box sx={{ height: "48px" }}> */}
                <LoginSocialTwitter
                  consumerKey={process.env.REACT_APP_TWITTER_CONSUMER_KEY}
                  consumerSecret={process.env.REACT_APP_TWITTER_CONSUMER_SECRET}
                  accessToken=""
                  accessTokenSecret=""
                  onResolve={handleTwitterSuccess}
                  onReject={(error) => {
                    console.error("Twitter login error:", error);
                    showSnackbar("Twitter login failed. Please try again.");
                  }}
                >
                  <TwitterLoginButton style={{ height: "48px" }} />
                </LoginSocialTwitter>
              {/* </Box> */}
            </Box>
          )}
          <Typography
            onClick={() => {
              setPageType(isLogin ? "register" : "login");
              setSignUpError(null);
              setLoginError(null);
            }}
            sx={{
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            {isLogin
              ? "Don't have an account? Sign Up here."
              : "Already have an account? Login here."}
          </Typography>
        </Box>
      </form>
      <ForgotPasswordModal
        open={forgotPasswordOpen}
        handleClose={() => setForgotPasswordOpen(false)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "red",
            color: "white",
          }}
          message={snackbarMessage}
          action={
            <Button color="inherit" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
        />
      </Snackbar>
    </>
  );
};

export default Form;
