
class LiveStreamPeerService {
    constructor() {
      this.peerConnections = new Map(); // Store peer connections for each viewer
      this.localStream = null;
      this.onIceCandidate = null;
      this.onAnswer = null;
    }
  
    // Initialize a new peer connection for a viewer
    createPeerConnection(viewerId) {
      const peerConnection = new RTCPeerConnection({
        iceServers: [
          { urls: 'stun:stun1.l.google.com:19302' },
          { urls: 'stun:stun2.l.google.com:19302' },
          { urls: 'stun:stun3.l.google.com:19302' }
        ]
      });
  
      console.log(`Created a new PeerConnection for viewer: ${viewerId}`);
  
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, this.localStream);
        });
      }
  
      // Handle ICE candidates
      peerConnection.onicecandidate = (event) => {
        if (event.candidate && this.onIceCandidate) {
            console.log(`Sending ICE candidate for viewer ${viewerId}:`, event.candidate);
          this.onIceCandidate(viewerId, event.candidate);
        }
      };
  
      // Store the peer connection
      this.peerConnections.set(viewerId, peerConnection);
      return peerConnection;
    }
  
    // Set local stream
    setLocalStream(stream) {
      this.localStream = stream;
      console.log("Local stream set for live streaming");
    }

    replaceTrackForAllPeers(newTrack) {
      this.peerConnections.forEach((peerConnection, viewerId) => {
          const sender = peerConnection.getSenders().find(s => s.track.kind === 'video');
          if (sender) {
              sender.replaceTrack(newTrack);
              console.log(`Replaced video track for viewer: ${viewerId}`);
          }
      });
  }
  
    // Create an offer for a specific viewer
    async createOffer(viewerId) {
      const peerConnection = this.peerConnections.get(viewerId);
      if (peerConnection) {
        try {
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);
          return offer;
        } catch (error) {
          console.error(`Error creating offer for viewer ${viewerId}:`, error);
        }
      }
      return null;
    }
  
    // Set remote description and add answer for a specific viewer
    async setRemoteDescription(viewerId, answer) {
      const peerConnection = this.peerConnections.get(viewerId);
      if (peerConnection) {
        try {
          await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
          console.log(`Remote description set for viewer: ${viewerId}`);
        } catch (error) {
          console.error(`Error setting remote description for viewer ${viewerId}:`, error);
        }
      }
    }
  
    // Add ICE candidate for a specific viewer
    async addIceCandidate(viewerId, candidate) {
      const peerConnection = this.peerConnections.get(viewerId);
      if (peerConnection) {
        try {
          await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
          console.log(`Added ICE candidate for viewer: ${viewerId}`);
        } catch (error) {
          console.error(`Error adding ICE candidate for viewer: ${viewerId}`, error);
        }
      }else{
        console.log("no peer connection found to add the ice candidate");
      }
    }
  
    // Set callback for ICE candidates
    setIceCandidateCallback(callback) {
      this.onIceCandidate = callback;
    }
  
    // Set callback for receiving answers
    setAnswerCallback(callback) {
      this.onAnswer = callback;
    }
  
    // Close the peer connection for a specific viewer
    closeConnection(viewerId) {
      const peerConnection = this.peerConnections.get(viewerId);
      if (peerConnection) {
        peerConnection.close();
        this.peerConnections.delete(viewerId);
        console.log(`Peer connection closed for viewer: ${viewerId}`);
      }
    }
  
    // Close all peer connections
    closeAllConnections() {
      this.peerConnections.forEach((peerConnection, viewerId) => {
        peerConnection.close();
        console.log(`Peer connection closed for viewer: ${viewerId}`);
      });
      this.peerConnections.clear();
    }
  }
  
  export default new LiveStreamPeerService();
  