import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import PostWidget from './PostWidget';

const SinglePostPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${baseURL}/posts/${postId}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch post');
        }
        
        const data = await response.json();
        setPost(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId, token, baseURL]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  if (!post) {
    return <Typography variant="body1">Post not found.</Typography>;
  }

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  let postImageUrl = "";
          
          // Check if content is valid JSON, otherwise treat it as plain text
          if (isJSON(post.content)) {
            const parsedContent = JSON.parse(post.content);
            if (Array.isArray(parsedContent) && parsedContent.length > 0) {
              postImageUrl = parsedContent[0]?.url || "";
            }
          } else {
            postImageUrl = ""; // Default value if content is not JSON
          }

  // Adapt the post data to match what PostWidget expects
  const adaptedPost = {
    postId: post.id,
    username: post.author.username,
    userId: post.authorId,
    title: post.title,
    postImage: postImageUrl,
    userPicturePath: post.author.image || "",
    likes: post.likedBy.length,
    comments: post.comments.length,
    // Add any other props that PostWidget expects
  };

  return (
    <Box maxWidth="600px" margin="auto" padding="20px">
      <PostWidget {...adaptedPost} />
    </Box>
  );
};

export default SinglePostPage;