import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import ProfileFriendListWidget from "scenes/widgets/ProfileFriendListWidget";
import ProfilePostsWidget from "scenes/widgets/ProfilePostsWidget";
import ProfileUserWidget from "scenes/widgets/ProfileUserWidget";
import UserWidget from "scenes/widgets/UserWidget";
import { SentimentDissatisfied } from "@mui/icons-material";

const ProfilePage = () => {
  const [user, setUser] = useState();
  const { palette } = useTheme();
  const [fetchUserDataLoading, setFetchUserDataLoading] = useState(false);
  const [fetchUserDataError, setFetchUserDataError] = useState("");
  const { username } = useParams();
  const token = useSelector((state) => state.auth.token);
  const usernameApp = useSelector((state) => state.auth.user.username);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getUser = async () => {
    setFetchUserDataLoading(true);
    setFetchUserDataError("");
    try {
      const response = await fetch(`${baseURL}/users/${username}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setFetchUserDataError(errorData.message);
      }
      const data = await response.json();
      setUser(data);
    } catch (error) {
      setFetchUserDataError(error.message);
    } finally {
      setFetchUserDataLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [username]);

  if (fetchUserDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (fetchUserDataError) {
    return (
      <Typography variant="body1" color="error">
        {fetchUserDataError}
      </Typography>
    );
  }

  return (
    <Box>
     
      <Box
        width="100%"
        padding="1rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          {user ? (
            username === usernameApp ? (
              <UserWidget />
            ) : (
              <ProfileUserWidget user={user} />
            )
          ) : (
            ""
          )}

          <Box m="2rem 0" />
          {user ? (
            username === usernameApp ? (
              <FriendListWidget />
            ) : (
              <ProfileFriendListWidget user={user} />
            )
          ) : (
            ""
          )}
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
        >
          {username === usernameApp ? <MyPostWidget /> : ""}

          <Box />
          {user ? (
            username === usernameApp ? (
              <PostsWidget isProfile />
            ) : (
              <ProfilePostsWidget user={user} />
            )
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="200px"
              textAlign="center"
              bgcolor={palette.background.default} // Background color
              borderRadius="8px" // Rounded corners
              boxShadow={3} // Shadow effect
              p="2rem"
            >
              <SentimentDissatisfied
                style={{ fontSize: 100, color: palette.primary.main }}
              />
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ mt: 2 }}
              >
                No posts available.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
