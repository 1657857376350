import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ReportPostModal = ({ open, handleClose, postId }) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const token = useSelector((state) => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleReportPost = async () => {
    if (!reason) {
      setSnackbarMessage("Please provide a reason for reporting.");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
        console.log(reason);
        console.log(postId);
      const response = await fetch(`${baseURL}/posts/report-post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reason,
          postId,
        }),
      });

      if (response.ok) {
        setSnackbarMessage("Post reported successfully.");
        handleClose(); // Close modal on success
        console.log("post reported successfully");
        toast.info("Post reported successfully");
      } else {
        setSnackbarMessage("Failed to report post.");
      }
    } catch (error) {
      setSnackbarMessage("Failed to report post.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          p: 4,
        }}
      >
        <Typography variant="h6" mb={2}>
          Report Post
        </Typography>
        <TextField
          label="Reason"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={reason}
          onChange={handleReasonChange}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleReportPost}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit Report"}
        </Button>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.includes("Failed") ? "error" : "success"}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default ReportPostModal;
