import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from "react-redux";
import {
  startStream,
  joinStream,
  setHostRoomId,
  fetchLiveStreams,
} from "../../state/streamSlice";
import HostStream from "./hostScreen";
import ViewerStream from "./viewerScreen";
import { useSocket } from "../../context/SocketProvider";

const StreamPage = () => {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { isHost, isViewer, liveStreams, isModalOpen, loading } = useSelector(
    (state) => state.livestream
  );
  const currentUser = useSelector((state) => state.auth.user);

  const timestamp = Number(new Date());
  const HOST_ROOM_ID = `${currentUser?.id ?? "First"}${timestamp}`;

  useEffect(() => {
    dispatch(fetchLiveStreams());
  }, [dispatch]);

  const handleStartStream = () => {
    dispatch(startStream());
    socket.emit("join-livesteam", {
      livesteamId: HOST_ROOM_ID,
      isHost: true,
      userId: currentUser?.id,
    });
    dispatch(setHostRoomId(HOST_ROOM_ID));
  };

  const handleJoinStream = (streamId) => {
    dispatch(joinStream(streamId));
    if (socket) {
      socket.emit("join-livesteam", {
        livesteamId: streamId,
        isHost: false,
      });
    }
  };

  const handleRefresh = () => {
    dispatch(fetchLiveStreams());
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
        margin: "2rem",
        borderRadius: 4,
        bgcolor: "white",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#D32F2F", fontWeight: "bold", mb: 3 }}
      >
        Live Streaming
      </Typography>

      <Button
        variant="contained"
        sx={{
          backgroundColor: "#D32F2F",
          color: "white",
          mb: 4,
          px: 4,
          py: 1.5,
          borderRadius: 2,
          textTransform: "none",
          fontSize: "1rem",
          "&:hover": {
            backgroundColor: "#B71C1C",
          },
        }}
        onClick={handleStartStream}
      >
        Start Live Stream
      </Button>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h5" gutterBottom sx={{ color: "#D32F2F", mb: 0 }}>
          Available Streams
        </Typography>
        <IconButton
          onClick={handleRefresh}
          sx={{ color: "#D32F2F", ml: 1 }}
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        >
          <CircularProgress sx={{ color: "#D32F2F" }} />
          <Typography sx={{ ml: 2, color: "#D32F2F" }}>
            Loading streams...
          </Typography>
        </Box>
      ) : liveStreams.length > 0 ? (
        <List sx={{ width: "100%", maxWidth: 600 }}>
          {liveStreams.map((stream) => (
            <ListItem
              key={stream.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                bgcolor: "#F5F5F5",
                borderRadius: 2,
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: "0 3px 6px rgba(0,0,0,0.15)",
                  transform: "translateY(-2px)",
                },
              }}
            >
              <ListItemText
                primary={`${stream.user.username}`}
                // secondary={stream.title || "Untitled Stream"}
                primaryTypographyProps={{ color: "#333", fontWeight: "medium" }}
                secondaryTypographyProps={{ color: "#666" }}
              />
              <Button
                variant="outlined"
                sx={{
                  color: "#D32F2F",
                  borderColor: "#D32F2F",
                  "&:hover": {
                    backgroundColor: "rgba(211, 47, 47, 0.04)",
                    borderColor: "#B71C1C",
                  },
                }}
                onClick={() => handleJoinStream(stream.id)}
              >
                Join
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="body1"
          sx={{ color: "#757575", mt: 3, textAlign: "center" }}
        >
          No live streams available at the moment.
          <br />
          Please check back later or start your own stream.
        </Typography>
      )}

      <Modal
        open={isModalOpen}
        disableEscapeKeyDown
        onClick={(e) => e.stopPropagation()}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: 3,
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
            p: { xs: 2, sm: 3, md: 4 },
            width: "80%",
            maxWidth: 800,
            maxHeight: "90vh",
            overflow: "auto",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {isHost && <HostStream />}
          {isViewer && <ViewerStream />}
        </Box>
      </Modal>
    </Box>
  );
};

export default StreamPage;