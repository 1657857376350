import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
  CircularProgress,
  InputBase,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  Menu,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  likePost,
  unlikePost,
  addLikeCount,
  removeLikeCount,
  addCommentCount,
  removeCommentCount,
  removePost,
  removeUserPost,
} from "../../state/index";
import ImageModal from "scenes/Chat/components/ImageModal";

const PostWidget = ({
  postId,
  username,
  postImage,
  title,
  userPicturePath,
  likes,
  comments,
  authorId,
}) => {
  const userLikes = useSelector((state) => state.auth.user.likes);
  const [isComments, setIsComments] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(
    userLikes?.some((like) => like.id === postId)
  );
  const [likeCount, setLikeCount] = useState(likes);
  const [commentInput, setCommentInput] = useState(""); // State for comment input
  const [postingComment, setPostingComment] = useState(false); // State for posting comment
  const [deletingPost, setDeletingPost] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const mainUsername = useSelector((state) => state.auth.user.username); // Assuming this is how you get the main user's username from state
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const handleImageClick = () => {
    setImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setImageModalOpen(false);
  };

  // const sendNotification = async (
  //   receiverUserId,
  //   notificationType,
  //   content
  // ) => {
  //   try {
  //     const notificationData = {
  //       notification: {
  //         title: notificationType === "like" ? "New Like" : "New Comment",
  //         body: content,
  //       },
  //       data: {
  //         type: notificationType === "like" ? "post_like" : "new_comment",
  //         typeId: postId,
  //         interactorUsername: mainUsername,
  //       },
  //     };

  //     const response = await fetch(
  //       `${baseURL}/users/${receiverUserId}/send-notification`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(notificationData),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to send notification");
  //     }

  //     console.log(`${notificationType} notification sent successfully`);
  //   } catch (error) {
  //     console.error(`Error sending ${notificationType} notification:`, error);
  //   }
  // };

  const handleCommentsClick = async () => {
    setIsComments(!isComments);
    if (!isComments && commentsData.length === 0) {
      setLoadingComments(true);
      try {
        const response = await fetch(`${baseURL}/posts/${postId}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setCommentsData(data.comments);
      } catch (error) {
        setCommentsError("Failed to fetch comments.");
      } finally {
        setLoadingComments(false);
      }
    }
  };

  const handleLikeClick = async () => {
    try {
      const response = await fetch(`${baseURL}/posts/${postId}/like`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        if (isLiked) {
          dispatch(unlikePost({ id: postId }));
          dispatch(removeLikeCount(postId));
          setSnackbarMessage("Post unliked.");
          setIsLiked(false);
          setLikeCount((prev) => prev - 1);
        } else {
          dispatch(likePost({ id: postId }));
          dispatch(addLikeCount(postId));
          setSnackbarMessage("Post liked.");
          setIsLiked(true);
          setLikeCount((prev) => prev + 1);
          // Send notification for new like
          // if (authorId !== mainUsername) {
          //   await sendNotification(
          //     authorId,
          //     "like",
          //     `${mainUsername} liked your post`
          //   );
          // }
        }
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to update like status.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to update like status.");
      setSnackbarOpen(true);
    }
  };

  const handleCommentInputChange = (e) => {
    setCommentInput(e.target.value);
  };

  const handlePostComment = async () => {
    setPostingComment(true);
    try {
      const response = await fetch(`${baseURL}/comments`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: commentInput,
          postId: postId,
        }),
      });

      if (response.ok) {
        const newComment = await response.json();
        setCommentsData((prevComments) => [newComment, ...prevComments]);
        dispatch(addCommentCount(postId));
        setCommentInput("");
        setSnackbarMessage("Comment posted successfully.");
        setSnackbarOpen(true);
        console.log("comment posted successfully");
        
        // Send notification for new comment
        // if (authorId !== mainUsername) {
        //   await sendNotification(
        //     authorId,
        //     "comment",
        //     `${mainUsername} commented on your post`
        //   );
        // }
      } else {
        setSnackbarMessage("Failed to post comment.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to post comment.");
      setSnackbarOpen(true);
    } finally {
      setPostingComment(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(`${baseURL}/comments/${commentId}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        setCommentsData((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
        dispatch(removeCommentCount(postId));
        setSnackbarMessage("Comment deleted successfully.");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to delete comment.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to delete comment.");
      setSnackbarOpen(true);
    }
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDeletePost = async () => {
    setDeletingPost(true);
    try {
      const response = await fetch(`${baseURL}/posts/${postId}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        dispatch(removePost({ id: postId }));
        dispatch(removeUserPost({ id: postId }));
        setSnackbarMessage("Post deleted successfully.");
        setSnackbarOpen(true);
      } else {
        setDeleteError("Failed to delete post.");
        setSnackbarMessage("Failed to delete post.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setDeleteError("Failed to delete post.");
      setSnackbarMessage("Failed to delete post.");
      setSnackbarOpen(true);
    } finally {
      setDeletingPost(false);
      handleMenuClose();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  return (
    <WidgetWrapper mb="2rem">
      <Friend
        username={username}
        userPicturePath={userPicturePath}
        handleDeletePost={handleDeletePost}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        menuAnchorEl={menuAnchorEl}
        menuOpen={menuOpen}
        deletingPost={deletingPost}
        postId={postId}
        isPost
      />

      <Typography color={main} sx={{ mt: "1rem" }}>
        {title}
      </Typography>

      {postImage && (
        <Box
          sx={{
            width: "100%",
            marginTop: "0.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="post"
            src={postImage}
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              width: "auto",
              height: "auto",
              borderRadius: "0.75rem",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={handleImageClick}
          />
        </Box>
      )}

      <FlexBetween mt="0.25rem">
        <FlexBetween gap="1rem">
          <FlexBetween gap="0.3rem">
            <IconButton onClick={handleLikeClick}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Typography>{likeCount}</Typography>
          </FlexBetween>

          <FlexBetween gap="0.3rem">
            <IconButton onClick={handleCommentsClick}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments}</Typography>
          </FlexBetween>
        </FlexBetween>
      </FlexBetween>

      {/* Comments section */}
      {isComments && (
        <Box
          mt="0.5rem"
          sx={{
            maxHeight: "250px",
            overflowY: "auto",
            minHeight: "auto",
            p: "0.5rem",
          }}
        >
          {loadingComments && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50px"
            >
              <CircularProgress />
            </Box>
          )}
          {commentsError && (
            <Typography color="error" sx={{ m: "0.5rem 0", pl: "1rem" }}>
              {commentsError}
            </Typography>
          )}
          {!loadingComments && !commentsError && commentsData.length === 0 && (
            <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
              No comments available.
            </Typography>
          )}
          {!loadingComments && !commentsError && (
            <>
              <FlexBetween gap="1.5rem">
                <InputBase
                  placeholder="Add Your Comment.."
                  value={commentInput}
                  onChange={handleCommentInputChange}
                  sx={{
                    width: "100%",
                    backgroundColor: palette.neutral.light,
                    borderRadius: "2rem",
                    padding: "1rem 2rem",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                />
                <Button
                  onClick={handlePostComment}
                  sx={{
                    color: palette.background.alt,
                    backgroundColor: palette.primary.main,
                    borderRadius: "3rem",
                    "&:hover": {
                      backgroundColor: palette.primary.dark,
                    },
                  }}
                >
                  {postingComment ? <CircularProgress size={24} /> : "POST"}
                </Button>
              </FlexBetween>

              {commentsData?.map((comment) => (
                <>
                  <Divider />
                  <Box key={comment.id} sx={{ my: "0.7rem" }}>
                    <Friend
                      username={comment.author.username}
                      subtitle={comment.content}
                      userPicturePath={
                        comment.author.image || "/assets/profile.jpg"
                      }
                      handleDeleteComment={handleDeleteComment}
                      commentId={comment.id}
                    />
                  </Box>
                </>
              ))}
            </>
          )}
        </Box>
      )}

<ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        imageUrl={postImage}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{
          "& .MuiAlert-root": {
            backgroundColor: primary,
            color: palette.background.alt,
          },
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes("Failed") ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </WidgetWrapper>
  );
};

export default PostWidget;
