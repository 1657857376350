import React, { useState } from "react";
import { Box, Typography, useTheme, Stack, CircularProgress, Button } from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UpdateProfileModal from "./UpdateProfileModal";
import UpdatePasswordModal from "./UpdatePasswordModal"; 
import ForgotPasswordModal from "./ForgotPasswordModel"; 

const UserWidget = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [updateProfileOpen, setUpdateProfileOpen] = useState(false);
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false); // State for ForgotPasswordModal
  const { user, fetchUserDataLoading, fetchUserDataError, followers, followings } = useSelector((state) => state.auth);
  const medium = palette.neutral.medium;

  const handleUpdateProfileOpen = () => setUpdateProfileOpen(true);
  const handleUpdateProfileClose = () => setUpdateProfileOpen(false);

  const handleUpdatePasswordOpen = () => setUpdatePasswordOpen(true);
  const handleUpdatePasswordClose = () => setUpdatePasswordOpen(false);

  const handleForgotPasswordOpen = () => setForgotPasswordOpen(true); // Open ForgotPasswordModal
  const handleForgotPasswordClose = () => setForgotPasswordOpen(false); // Close ForgotPasswordModal

  return (
    <WidgetWrapper>
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        onClick={() => navigate(`/profile/${user.username}`)}
      >
        <FlexBetween gap="1rem">
          <UserImage image={user?.image} size="55px" />
          <Box>
            <Typography
              variant="h4"
              color={palette.primary.main}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              {user?.firstName} {user?.lastName}
            </Typography>
          </Box>
        </FlexBetween>
      </FlexBetween>
      <Box>
        <Typography>{user?.bio}</Typography>
        <br />
        <Stack direction={"row"} gap={"20px"}>
          {fetchUserDataLoading ? (
            <CircularProgress size={20} />
          ) : fetchUserDataError ? (
            <Typography color="error">{fetchUserDataError}</Typography>
          ) : (
            <>
              <Typography color={medium}>{followers.length} followers</Typography>
              <Typography color={medium}>{followings.length} following</Typography>
            </>
          )}
        </Stack>
        <br />
        <Stack direction={"row"} gap={"20px"}>
          <Button onClick={handleUpdateProfileOpen}>Update Profile</Button>
          <Button onClick={handleUpdatePasswordOpen}>Update Password</Button>
        </Stack>
        <UpdateProfileModal open={updateProfileOpen} handleClose={handleUpdateProfileClose} />
        <UpdatePasswordModal 
          open={updatePasswordOpen} 
          handleClose={handleUpdatePasswordClose}
          handleForgotPasswordOpen={handleForgotPasswordOpen} // Pass the open handler
        />
        <ForgotPasswordModal
          open={forgotPasswordOpen} 
          handleClose={handleForgotPasswordClose}
        />
      </Box>
    </WidgetWrapper>
  );
};

export default UserWidget;
