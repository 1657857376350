import { useSelector } from "react-redux";
import PostWidget from "./PostWidget";
import { CircularProgress, Typography, Box } from "@mui/material";

const PostsWidget = ({ isProfile = false }) => {
  const {
    fetchPostsLoading,
    fetchPostsError,
    fetchUserDataLoading,
    fetchUserDataError,
    userPosts,
    posts,
  } = useSelector((state) => state.auth);

  if (isProfile) {
    if (fetchUserDataLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (fetchUserDataError) {
      return <Typography variant="body1" color="error">{fetchUserDataError}</Typography>;
    }

    if (!userPosts || userPosts.length === 0) {
      return <Typography variant="body1">No posts available.</Typography>;
    }

  } else {
    if (fetchPostsLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (fetchPostsError) {
      return <Typography variant="body1" color="error">{fetchPostsError}</Typography>;
    }

    if (!posts?.data || posts.data.length === 0) {
      return <Typography variant="body1">No posts available.</Typography>;
    }
  }

  const postList = isProfile ? userPosts : posts.data;
  
  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {postList.map(
        ({
          id,
          title,
          content,
          author,
          _count, // Object containing comments and likedBy
          authorId,
        }) => {
          let postImageUrl = "";
          
          // Check if content is valid JSON, otherwise treat it as plain text
          if (isJSON(content)) {
            const parsedContent = JSON.parse(content);
            if (Array.isArray(parsedContent) && parsedContent.length > 0) {
              postImageUrl = parsedContent[0]?.url || "";
            }
          } else {
            postImageUrl = ""; // Default value if content is not JSON
          }
          

          return (
            <PostWidget
              key={id}
              username={author.username}
              userId={authorId}
              postId={id}
              title={title}
              name={`${author?.firstName || ""} ${author?.lastName || ""}`}
              postImage={postImageUrl} // Now passing the correct image URL or an empty string
              userPicturePath={author?.image || ""}
              likes={_count?.likedBy || 0}
              comments={_count?.comments || 0}
            />
          );
        }
      )}
    </>
  );
};

export default PostsWidget;