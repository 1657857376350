import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Gear } from "phosphor-react";
import { Nav_Buttons, Profile_Menu } from "../../data";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectConversation } from "state/chat";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "app";

    case 1:
      return "group";

    default:
      return "app";
  }
};

const SideBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize useDispatch
  const profile = useSelector((state) => state.auth.user.image);

  // Load selected index from localStorage
  const [selected, setSelected] = useState(() => {
    const savedIndex = localStorage.getItem("selectedNavIndex");
    return savedIndex !== null ? parseInt(savedIndex) : 0;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(
      selectConversation({
        chatType: "personal",
        roomId: null,
        name: null,
        chatOpen: null,
      })
    );
    dispatch(
      selectConversation({
        chatType: "group",
        roomId: null,
        name: null,
        chatOpen: null,
      })
    );
    navigate(getPath(selected));
    localStorage.setItem("selectedNavIndex", selected); // Save selected index to localStorage
  }, [selected, navigate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = (index) => {
    setSelected(index);

    // Dispatch action to set chatOpen to null for both personal and group chats
    dispatch(
      selectConversation({
        chatType: "personal",
        roomId: null,
        name: null,
        chatOpen: null,
      })
    );
    dispatch(
      selectConversation({
        chatType: "group",
        roomId: null,
        name: null,
        chatOpen: null,
      })
    );
  };

  return (
    <Box
      p={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        height: "100%",
        width: 100,
      }}
    >
      <Stack
        direction="column"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ height: "100%" }}
        spacing={3}
      >
        <Stack alignItems={"center"} spacing={4}>
          <Stack
            sx={{ width: "max-content" }}
            direction="column"
            alignItems="center"
            spacing={3}
          >
            {Nav_Buttons.map((el, index) => (
              <React.Fragment key={el.index}>
                {el.index === selected ? (
                  <Box
                    p={1}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 1.5,
                    }}
                  >
                    <IconButton sx={{ width: "max-content", color: "#fff" }}>
                      {el.icon}
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton
                    onClick={() => handleIconClick(el.index)}
                    sx={{
                      width: "max-content",
                      color:
                        theme.palette.mode === "light"
                          ? "#000"
                          : theme.palette.text.primary,
                    }}
                  >
                    {el.icon}
                  </IconButton>
                )}

                {/* Add Divider after each button except the last one */}
                {index !== Nav_Buttons.length - 1 && (
                  <Divider sx={{ width: "48px" }} />
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Stack>

        <Stack spacing={4}>
          <Avatar
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            src={profile && profile}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack spacing={1} px={1}>
              {Profile_Menu.map((el) => (
                <MenuItem key={el.title} onClick={handleClose}>
                  <Stack
                    sx={{ width: 100 }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <span>{el.title}</span>
                    {el.icon}
                  </Stack>
                </MenuItem>
              ))}
            </Stack>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SideBar;
